export default {
	zh: {
		home: '首页',
		technology: '核心技术',
		scene: '应用场景',
		product: '购买产品',
		news: '最新动态',
		about: '关于我们',
		join: '加入我们',
		contact: '联系我们',
		search: '搜索',
    sceneDetail: '场景详情',
    newsDetail: '动态详情',
    joinDetail: '加入详情'
	},
	en: {
		home: 'Home',
		technology: 'Technology',
		scene: 'Applications',
		product: 'Products',
		news: 'News',
		about: 'About Us',
		join: 'Careers',
		contact: 'Contact',
		search: 'Search',
    sceneDetail: 'Applications Detail',
    newsDetail: 'News Detail',
    joinDetail: 'Careers Detail'
	},
}