<template>
	<div class="head_content">
		<div class="logo">
			<img @click="showHome()" src="@/assets/logo_blue_4.png" />
		</div>
		<div class="nav_content">
			<div class="nav_content_info">
        <template v-for="(item, index) in menuList">
          <div :class="['single_nav', selectBar === index ? 'single_nav_select':'single_nav_unselect']" :key="index" @click="showPage(item)">
						<a-dropdown>
							<div style="line-height:30px" @click.prevent>{{$t('nav.' + item)}}</div>
							<template #overlay>
								<div class="nav_drop_content" v-if="item === 'scene'">
									<template v-for="(item, index) in sceneList">
										<div :key="index" @click="showScenceDetail(item.key)">{{$t('scene.title' + item.id)}}</div>
									</template>
								</div>
							</template>
						</a-dropdown>
					</div>
        </template>
      </div>
		</div>
		<div class="search_content">
			<div class="search_info" @click="begainFocus()" v-if="!showSeach">
				<span>{{$t('nav.search')}}</span>
				<img src="@/assets/search.png"/>
			</div>
			<input ref="inputText" v-else v-model="searchText" @keyup.enter="onSearch()" @blur="showSeach=false;searchText=null"/>
		</div>
		<!-- <div class="language_content">
			<div :class="[zhcn?'select_nav':'unselect_nav']" @click="lanClick()">�/div>
			<div class="middle_lan">/</div>
			<div :class="[zhcn?'unselect_nav':'select_nav']" @click="lanClick()">EN</div>
		</div> -->
	</div>
</template>

<script>
	import {
		mapActions,
		mapGetters
	} from 'vuex';
	import common from '@/mixin/common.js';
	export default {
    name: 'r-header-pc',
		mixins: [common],
		computed: {
			...mapGetters('scene', ['sceneList']),
		},
    props: {
      menuList: {
        type: Array,
        default: []
      }
    },
		data() {
			return {
				searchText: null,
        selectBar: -1,
				showSeach: false,
			}
		},
		methods: {
			...mapActions('language', ['changeLanguage']),
			showPage(params) {
				this.$router.push('/' + params);
			},
			lanClick() {
        // this.changeLanguage(this.zhcn ? 'en' : 'zh');
		this.changeLanguage('en');
			},
			onSearch() {
				if (!this.searchText) return;
				this.$router.push('/search/' + encodeURIComponent(this.searchText));
			},
      showHome() {
				this.$router.push('/');
      },
			begainFocus() {
				this.showSeach = true;
				this.$nextTick(()=>{
					console.log(this.$refs.inputText)
					this.$refs.inputText.focus();
				})
			},
			showScenceDetail(params) {
      	this.$router.push(`/scene/detail#${params}`);
			}
		},
    watch: {
      $route: function() {
        try {
          const item = this.$route.matched[0];
          const name = item.name || item.meta.breadName;
          const index = this.menuList.indexOf(name);
          this.selectBar =  index >= -1 ? index : 0;
        } catch (error) {
          console.log(' change select error ', error);
        }
      }
    }
	}
</script>

<style lang="scss">
	// @font-face {
	// 	font-family: 'Lantinghei';
	// 	src: url('../assets/font/Lantinghei.ttf') format('truetype');
	// 	font-weight: normal;
	// 	font-style: normal;
	// }
</style>

<style scoped lang="scss">

	$TEXTMARGIN: 36px;

	.nav_drop_content {
		background: white;
		width: 120%;
		margin-top: 25px;
		margin-left: -25%;
		color: #333333;
		font-size: 12px;
		letter-spacing: 1px;
    border-left: 1px solid #EFEFEF;
    border-right: 1px solid #EFEFEF;
		div {
			width: 100%;
			height: 40px;
			display: flex;
			justify-content: center;
			align-items: center;
			border-top: 1px solid #EFEFEF;
			cursor: pointer;
		}
		div:hover {
			background: rgb(229, 240, 253);
		}
	}

  .single_nav_select{
    border-bottom: 2px solid #407FC9;
  }
  .single_nav_unselect {
    border-bottom: 2px solid transparent;
  }
	.head_content {
		min-width: $page-max-width;
		display: flex;
		align-items: center;
		justify-content: center;
    height: 100%;
		margin: 0 auto;
		.logo {
			img {
				// width: 133px;
				// height: 54px;
				width:168px
			}
		}

		.nav_content {
      margin-left: 120px;
      min-width: 732px;
			display: flex;
      align-items: center;
			
      .nav_content_info {
        width: 100%;
        display: flex;
        justify-content: space-between;
        .single_nav {
          display: flex;
          align-items: center;
          cursor: pointer;
          height: 30px;
					div {
						font-family: $SourceHanSansCNMedium !important;
						font-size: 14px;
						letter-spacing: 1px;
						color: #333333;
						font-weight: 600;
					}
        }
      }
		}

		.search_content {
			width: 116px;
			height: 34px;
			border: 1px solid #000000;
			border-radius: 5px;
			overflow: hidden;
			display: flex;
			align-items: center;
			margin: 0 $TEXTMARGIN;
			padding: 0 5px;
      display: flex;
      align-items: center;
			.search_info {
				margin: 0 auto;
				display: flex;
				align-items: center;
				letter-spacing: 1px;
				span {
					margin-right: 2px;
					// font-family: Lantinghei !important;
				}
				img {
					width: 15px;
				}
			}
			input {
				border: none;
				outline: none;
				height: 30px;
        line-height: 30px;
				width: 85px;
				text-align: center;
				font-size: 14px;
				letter-spacing: 2px;
				color: #000000;
			}
			.search_icon {
				width: 20px;
				height: 100%;
				display: flex;
				justify-content: center;
				align-items: center;
				cursor: pointer;
			}

		}

		.language_content {
			// font-family: Lantinghei !important;
			display: flex;
			font-size: 14px;
			letter-spacing: 2px;

			div {
				display: flex;
				align-items: center;
				height: 30px;
				cursor: pointer;
			}

			.middle_lan {
				margin: 0 2px;
			}
		}
	}

	.select_nav {
		border-bottom: 2px solid #407FC9;
	}

	.unselect_nav {
		border-bottom: 2px solid transparent;
	}
</style>
