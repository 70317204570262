import * as cookie from '@/util/cookie.js';

// const ZHNAME = 'zh';
const ZHNAME = 'zh';
const ENNAME = 'en';

// 系统语言管理

export default {
	namespaced: true,
	state: {
		// zhcn: true,
		zhcn: false,
		zhStoreKey: 'alpsentekchkey',
		// zhPageKey: '_cn', // 后端返回的字段取值
		zhPageKey: '_en', // 后端返回的字段取值
	},
	getters: {
		zhcn: state => state.zhcn,
		zhStoreKey: state => state.zhStoreKey,
		zhPageKey: state => state.zhPageKey,
	},
	actions: {
		// initLanguage({
		// 	state,
		// 	dispatch
		// }) {

		// 	const result = cookie.getCookie(state.zhStoreKey);
		// 	if (result && (result === ENNAME || result === ZHNAME)) {
		// 		const lan = (result && result === ENNAME) ? ENNAME : ZHNAME;
		// 		dispatch('setLanguage', lan);
		// 	} else {
		// 		// const jsSrc = (navigator.language || navigator.browserLanguage).toLowerCase();
		// 		// 取消 切换语言功能 默认英文
		// 		// if(jsSrc.indexOf('zh') >= 0){
		// 		// 	// 假如浏览器语言是中文
		// 		// 	dispatch('changeLanguage', ZHNAME)
		// 		// } else if(jsSrc.indexOf('en') >= 0) {
		// 		// 		// 假如浏览器语言是英文
		// 		// 	dispatch('changeLanguage', ENNAME)
		// 		// } 
		// 		// dispatch('changeLanguage', ENNAME)
		// 	}
		// },
		setLanguage({
			state
		}, params = ZHNAME) {
			// state.zhcn = params === ZHNAME ? true : false;
			state.zhcn = false;
			// state.zhPageKey = params === ZHNAME ? '_cn' : '_en';
			state.zhPageKey = '_en'
		},
		// changeLanguage({
		// 	state,
		// 	dispatch
		// }, params) {
		// 	cookie.setCookie(state.zhStoreKey, params);
		// 	dispatch('setLanguage', params);
		// },
	},
}
