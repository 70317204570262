<template>
  <div
    :class="['banner_content', 'banner_content' + mobilePrefix]"
    :style="{'background-image':`url(${bgImage})`, color: color}"
  >
    <div class="content">
      <div class="title" v-if="type">{{$t('nav.' + type)}}</div>
    </div>
  </div>
</template>

<script>
import common from '@/mixin/common.js';
export default {
  name: 'r-banner',
  mixins: [common],
  data() {
    return {
      bgImage: null,
      type: null,
      color: '#929EA3'
    }
  },
  mounted() {
    try {
      this.type = this.$route.matched[0].meta.breadName;
      if (this.type === 'about') {
        if (this.isMobileBrowse) {
          this.bgImage = require('@/assets/about/m_banner.jpg');
          this.color = '#FFFFFF';
        } else {
          this.bgImage = require('@/assets/about/banner.jpg');
          this.color = '#929EA3';
        }
      } else if (this.type === 'product') {
        if (this.isMobileBrowse) {
          this.bgImage = require('@/assets/product/m_banner.jpg');
          this.color = '#687C94';
        } else {
          this.bgImage = require('@/assets/product/banner.jpg');
          this.color = '#FFFFFF';
        }
      } else if (this.type === 'contact') {
        if (this.isMobileBrowse) {
          this.bgImage = require('@/assets/contact/m_banner.jpg');
          this.color = '#687C94';
        } else {
          this.bgImage = require('@/assets/contact/banner.jpg');
          this.color = '#878D94';
        }
      } else if (this.type === 'join') {
        if (this.isMobileBrowse) {
          this.bgImage = require('@/assets/join/m_banner.jpg');
          this.color = '#687C94';
        } else {
          this.bgImage = require('@/assets/join/banner.jpg');
          this.color = '#FFFFFF';
        }
      } else if (this.type === 'news') {
        if (this.isMobileBrowse) {
          this.bgImage = require('@/assets/news/m_banner.jpg');
          this.color = '#687C94';
        } else {
          this.bgImage = require('@/assets/news/banner.jpg');
          this.color = '#9EB0C5';
        }
      } else if (this.type === 'scene' || this.type === 'sceneDetail') {
        if (this.isMobileBrowse) {
          this.bgImage = require('@/assets/scene/m_banner.jpg');
          this.color = '#FFFFFF';
        } else {
          this.bgImage = require('@/assets/scene/banner.jpg');
          this.color = '#D4DAD8';
        }
      } else if (this.type === 'technology') {
        if (this.isMobileBrowse) {
          this.bgImage = require('@/assets/technology/m_banner.jpg');
          this.color = '#FFFFFF'
        } else {
          this.bgImage = require('@/assets/technology/banner.jpg');
          this.color = '#9EB0C5'
        }
      }
    } catch (error) {
      console.log(' cant fetch banner ');
    }
  }
}
</script>

<style lang="scss" scoped>
.banner_content {
  // max-width: $page-max-width;
  // width: 100% !important;
  width: 100%;
  margin: 0 auto;
  height: 500px;
  overflow: hidden;
  background-repeat: no-repeat !important;
  background-size: cover !important;
  background-position: center center !important;
  color: #9EB0C5;
  font-size: 32px;
  letter-spacing: 8px;
  font-weight: 600;
  display: flex;
  align-items: center;
  justify-content: center;

  .content {
    flex: 1;
    max-width: $page-max-width;
    margin: 0 auto;

    .title {
      margin-left: 70px;
    }
  }
}
.banner_content_mobile {
  height: 187px;
  font-size: 16px;
  letter-spacing: 1.67px;

  .content {
    .title {
      margin-left: 40px;
    }
  }
}
</style>