// 将当前目录下的文件导出去

const files = require.context('.', true, /\.js$/);
let routes = [];

files.keys().forEach(key => {
	// 过滤本身
	if (key === './index.js') return;
	// 切割key的首尾
  routes.push(files(key).default);
});


routes = routes.concat([{
  path: '/:home?', // 首页
  name: 'home',
  component: () => import( /* webpackChunkName: "Home" */ '@/views/home/index.vue'),
},{
  path: '*', // 404 
  name: '404',
  component: () => import( /* webpackChunkName: "404" */ '@/views/404.vue'),
}]);

if (process.env.NODE_ENV === 'development') {
  console.log(' == routes ==', routes)
}

export default routes;
