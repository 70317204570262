import error from './error.js';
import Client from '@/util/api/client.js'


const CODEKEY = 'code';
const CODEVALUE = 0;
const DATAKEY = 'data';
const MESSAGEKEY = 'message';

export default {
	namespaced: true,
	state: {
		client: null, // 请求基础类
	},
	getters: {
		client: state => state.client,
	},
	actions: {
		initAPI({
			dispatch,
			state,
			rootState
		}, params = null) {
			state.client = new Client();
			state.client.setDomain(params || rootState.domain);
			
			state.client.setShowLoading(() => {
				dispatch('showLoading');
			});

			state.client.setHideLoading(() => {
				dispatch('hidenToast');
			});
		},
		setToken({
			state
		}, params = null) {
			state.client.setToken(params)
		},
		showLoading({
			state
		}, message) {
			// Toast.loading({
			// 	message: message || '加载中...',
			// 	forbidClick: true,
			// });
		},
		hidenToast() {
			// Toast.clear();
		},
		// 请求api的统一入口
		async fetchAPI({
			state
		}, options = null) {
			try {
				if (!options || !options.opt) {
					throw 'options error';
				}
				if (process.env.NODE_ENV !== 'production') {
					console.warn(' u shoul reset api response for result ' );
				}
				
				// 判断是否需要菊花转圈  默认是需要
				options.showLoading = (typeof options.showLoading === 'boolean') ? options.showLoading : true;

				let result = await state.client.request(options);
				// 整理成统一的数据，返回出去
				const isArray = Array.isArray(options.opt);
				result = isArray ? result : [result];
				// result = result.map(item => {
				// 	return {
				// 		isSuccess: item[CODEKEY] === CODEVALUE,
				// 		code: item[CODEKEY],
				// 		data: item[DATAKEY],
				// 		message: item[MESSAGEKEY]
				// 	}
				// });
				
				// 错误内容弹窗
				const errorItem = result.find(item => !item.isSuccess);
				if (errorItem) {
					const messageItem = error.find((item)=>{
						return parseInt(item[CODEKEY]) === errorItem.code;
					});
					// const isPro = process.env.NODE_ENV === 'production';
					// const isMessage = (messageItem && messageItem.message);
					// Toast( isMessage ? messageItem.message : (isPro ? errorItem.message : '网络开小差'));
				}
				return isArray ? result : result[0];
			} catch (e) {
				console.warn(' fetchAPI error' + e + ' u can copy the temp options', {
					showLoading: true,
					beforeSend: () => {},
					complete: () => {},
					opt: {
						url: '/user/info',
						method: 'GET',
						baseURL: ''
					}
				})
				return;
			}
		}
	},
}
