import Vue from 'vue'
import VueRouter from 'vue-router'
import routes from './routes';


// 解决Ant-ui或者element-ui 导航栏中的vue-router在3.0版本以上重复点菜单报错问题
// Error: Avoided redundant navigation to current location:
const originalPush = VueRouter.prototype.push
VueRouter.prototype.push = function push(location) {
  return originalPush.call(this, location).catch(err => err)
}

Vue.use(VueRouter)

const router = new VueRouter({
	mode: 'history',
	base: process.env.BASE_URL,
	routes: routes,
	scrollBehavior (to, from, savedPosition) {
		if (savedPosition) {
			// savedPosition is only available for popstate navigations.
			return savedPosition;
		} else {
			const position = {};
			// new navigation.
			// scroll to anchor by returning the selector
			if (to.hash) {
				position.selector = to.hash;
				position.offset = { y: 104 };
			} else {
				position.x = 0;
				position.y = 0;
			}
			// check if any matched route config has meta that requires scrolling to top
			// if (to.matched.some(m => m.meta.scrollToTop)) {
			// 	// cords will be used if no selector is provided,
			// 	// or if the selector didn't match any element.
			// 	position.x = 0;
			// 	position.y = 0;
			// }
			// if the returned position is falsy or an empty object,
			// will retain current scroll position.
			return position;
		}
	}
})

export default router
