<template>
	<div>
		<div :class="['footer_content', 'footer_content' + mobilePrefix]" :style="{background:`url(${footBgImage})`}">
			<div class="foot_head">
				<a-row justify="space-between" type="flex">
					<a-col :xs="24" :sm="24" :md="13" :lg="13" :xl="13">
						<div>
							<div class="foot_address_title">
								<img src="@/assets/logo_white_new.png" />
							</div>
							<div :class="['foot_address_info']">
								<template v-for="(item, index) in addressInfo">
									<div :key="index" class="foot_address_single" @click="showContact()">
										<div class="foot_address_single_title">{{$t('foot.addressInfoTitle' + (index+1))}}</div>
										<div class="foot_address_single_addr" v-html="$t('foot.addressInfoDes' + (index+1))"></div>
									</div>
								</template>
							</div>
						</div>
					</a-col>
					<a-col :xs="24" :sm="24" :md="9" :lg="9" :xl="9" v-if="!isMobileBrowse">
						<div :class="['foot_nav', zhcn?'':'f_h4_en']">{{$t('foot.footTitle')}}</div>
						<div class="foot_nav_info">
							<div class="foot_nav_content">
								<div :class="['foot_nav_content_info', zhcn?'':'foot_nav_en']">
									<template v-for="(item, index) in navList">
										<div :key="index" @click="showPage(item)">{{$t('foot.' + item)}}</div>
									</template>
								</div>
							</div>
							<div class="foot_nav_icon">
								<!-- <div class="foot_nav_wechat">
									<div v-if="showWechatImage" class="foot_nav_hover">
										<img src="@/assets/contact/QeCode.jpeg" />
									</div>
									<img @mouseenter="showWechatImage=true" @mouseleave="showWechatImage=false" src="@/assets/foot/ic_wechat.png" />
								</div> -->
								<img @click="inTap()" src="@/assets/foot/ic_in.png" />
							</div>
						</div>
					</a-col>
				</a-row>
			</div>

			<div v-if="!isMobileBrowse" class="foot_line"></div>

			<!-- 联系方式内容 -->
			<div :class="['foot_message']">
				<a-row justify="space-between" type="flex">
					<a-col :xs="24" :sm="24" :md="12" :lg="12" :xl="12">
						<div :class="['message_info', zhcn?'':'f_h4_en']">
							<template v-for="(item, index) in messageInfo">
								<div :key="index" class="message_info_content">
									<img :src="item.image"/>
									<div>
										<a :href="item.type" v-html="$t('foot.messageInfoTitle' + (index+1))"></a>
										</div>
								</div>
							</template>
						</div>
					</a-col>
					<a-col :xs="24" :sm="24" :md="9" :lg="9" :xl="9">
						<div class="message_email_content" v-if="!isMobileBrowse">
							<div class="message_email">
								<div>
									<div :class="['message_email_title', zhcn?'':'f_h4_en']">{{$t('foot.footSubMessage')}}</div>
									<div :class="['message_email_input', zhcn?'':'f_h4_en']">
										<input :placeholder="$t('foot.emailPlacehoder')" v-model="email" />
										<div @click="sendEmail()" >{{$t('foot.send')}}</div>
									</div>
								</div>
							</div>
						</div>
						<div class="message_mobile_info" v-else>
							<div class="foot_line"></div>
							<!-- mobile footer -->
							<div v-if="showWechatImage" class="foot_nav_hover">
								<img src="@/assets/contact/wechat.jpg" />
							</div>
							<div>
								<!-- <div class="message_mobile_icon">
									<img @click="showWechatImage=!showWechatImage" src="@/assets/foot/ic_wechat.png">
								</div> -->
								<!-- <div class="message_mobile_wechat">
									<img src="@/assets/contact/wechat.jpg"/>
								</div> -->
								<div class="message_mobile_icon" @click="inTap()">
									<img src="@/assets/foot/ic_in.png">
								</div>
							</div>
							<div>
								<div class="message_mobile_title">{{$t('foot.footSubMessage')}}</div>
								<div class="message_mobile_input">
									<input :placeholder="$t('foot.emailPlacehoder')" v-model="email" />
									<div @click="sendEmail">{{$t('foot.send')}}</div>
								</div>
							</div>
						</div>
					</a-col>
				</a-row>
			</div>
		</div>
		<!-- 备案号 -->
		<div :class="['copy_right', 'copy_right' + mobilePrefix]">{{$t('foot.record')}}</div>
	</div>
</template>

<script>
	import common from '@/mixin/common.js'
	import { mapActions } from 'vuex';

	export default {
		name: 'r-footer',
		mixins: [common],
		data() {
			return {
				footBgImage: require('@/assets/foot/foot_bg.jpg'),
				// addressInfo: ['北京', '深圳','南京', '苏黎世'],
				addressInfo: ['苏黎世'],
				messageInfo: [
					{
						image: require('@/assets/foot/ic_phone.png'),
						type: 'tel:+8675526911746',
					},{
						image: require('@/assets/foot/ic_email.png'),
						type: 'mailto:alpsentek@alpsentek.com'
					},{
						image: require('@/assets/foot/ic_email.png'),
						type: 'mailto:talent@alpsentek.com'
					}
				],
				email: null,  // 用户输入的邮箱
				navList: ['home', 'news', 'technology', 'about', 'scene', 'join', 'product', 'contact'],
				showWechatImage: false,
			}
		},
		methods: {
			...mapActions('home', ['beaginSubscribe']),
			async sendEmail() {
				const reg = /^[A-Za-z\d]+([-_.][A-Za-z\d]+)*@([A-Za-z\d]+[-.])+[A-Za-z\d]{2,4}$/;
				if (reg.test(this.email)) {
					// const result = await this.beaginSubscribe(this.email); 
					// this.$message.success(result ? '订阅成功' : '订阅失败');
					var url = `mailto:talent@alpsentek.com?subject=Subscribe for News And Updates&body=Subscribe to push to ${this.email}`
					window.location.href = url
				} else {
					// this.$message.warning('邮箱格式不正确');
					this.$message.warning('Incorrect mailbox format')
				}
			},
			showPage(params) {
				this.$router.push('/' + params);
			},
			showContact() {
				this.$router.push('/contact');
			},
			inTap() {
				window.open('https://www.linkedin.com/company/31467119/');
			},
			
		},
	}
</script>

<style scoped lang="scss">
	.footer_content {
		width: 100%;
		background-repeat: no-repeat !important;
		background-size: cover !important;
		background-position: center center !important;
		color: rgba(255, 255, 255, 0.6);

		.foot_head {
			// max-width: $page-max-width;
			// max-width: 960px;
			max-width: 1000px;
			margin: 0 auto;
			padding-top: 62px;
			letter-spacing: 1px;

			.foot_address_title {
				display: flex;
				font-size: 15px;
				font-weight: bold;
				margin-bottom: 50px;
				overflow: hidden;
				img {
					// width: 144px !important;
					width: 168px !important;
					// height: 57px !important;
				}
				div {
					margin-left: 20px;
					margin-top: 20px;
				}
			}

			.foot_address_info {
				font-size: 14px;
				letter-spacing: 0px;
				line-height: 24px;
				.foot_address_single {
					cursor: pointer;
					margin-bottom: 20px;
					display: flex;
					align-items: flex-start;
					.foot_address_single_title {
						// min-width: 60px;
						width: 90px;
					}
					.foot_address_single_addr {
						flex: 1;
					}
				}
			}

			.foot_nav {
				font-weight: 600;
				font-size: 16px;
				color: white;
				margin-top: 19px;
				line-height: 25px;
			}
			.foot_nav_info {
				display: flex;
				justify-content: space-between;
				width: 100%;
				font-size: 14px;
				letter-spacing: 0px;
				.foot_nav_content {
					flex: 1;
					margin-top: 30px;
					.foot_nav_en {
						margin-top: 26px;
						height: 184px;
					}
					.foot_nav_content_info {
						display: flex;
						// justify-content: space-between;
						flex-wrap: wrap;
						width: 250px;
						div {
							width: 50%;
							line-height: 38px;
							cursor: pointer;
						}
					}
				
				}
				.foot_nav_icon {
					display: flex;
					flex-direction: column;
					margin-top: 30px;
					.foot_nav_wechat {
						position: relative;
						width: 24px;
						height: 24px;
						margin-bottom: 20px;
						.foot_nav_hover {
							position: absolute;
							right: 30px;
							top: 10px;
							width: 210px;
							height: 210px;
							display: flex;
							justify-content: center;
							align-items: center;
							background: white;
							img {
								width: 200px;
								height: 200px;
							}
						}
					}
					img {
						margin: 10px 0;
						width: 24px;
						height: 24px;
						cursor: pointer;
					}
				}
			}
		}

		.foot_line {
			margin: 40px 10% 60px;
			height: 1px;
			background: rgba(255,255,255, 0.4);
		}

		.foot_message {
			// max-width: $page-max-width;
			// max-width: 960px;
			max-width: 1000px;
			margin: 0 auto;
			padding-bottom: 62px;
			letter-spacing: 0px;
			font-size: 14px;

			.message_info {
				.message_info_content {
					display: flex;
					align-items: center;
					margin-bottom: 14px;
					img {
						max-width: 20px;
						margin-right: 10px;
					}
					a {
						color: rgba(255, 255, 255, 0.6);
					}
				}
			}

			.message_email_content {
				width: 100%;

				.message_email {
					display: inline-block;
					width: 100%;

					.message_email_title {
						width: 100%;
						text-align: left;
						margin: 0 auto 20px auto;
						font-weight: 600;
						color: white;
					}

					.message_email_input {
						display: flex;
						align-items: center;
						font-size: 14px;
						width: 100%;
						height: 48px;

						input {
							flex: 1;
							border: none;
							width: 249px;
							outline: none;
							background: transparent;
							letter-spacing: 1px;
							color: #bcbcbc;
							padding-left: 42px;
							height: 48px;
							border-top-left-radius: 4px;
							border-bottom-left-radius: 4px;
							border-bottom: 1px solid rgba(150,150,150,0.60);
							border-top: 1px solid rgba(150,150,150,0.60);
							border-left: 1px solid rgba(150,150,150,0.60);
						}

						div {
							width: 98px;
							height: 100%;
							display: flex;
							justify-content: center;
							align-items: center;
							letter-spacing: 2px;
							background: rgba(150,150,150,0.60);
							cursor: pointer;
							border-bottom-right-radius: 4px;
							border-top-right-radius: 4px;
						}
					}
				}
			}
		}
	}
	
	// mobile
	.footer_content_mobile {
		padding: 0 40px;
		
		.foot_head {
			.foot_address_title {
				font-size: 12px;
			}

			.foot_address_info {
				font-size: 12px;
				line-height: 17px;
			}
		}

		.foot_line {
			width: 100%;
			background: rgba(255,255,255,0.20);
			height: 1px;
			margin: 0;
		}

		.foot_message {

			.message_info {
				margin: 20px 0 30px;
				padding-left: 5px;
				font-size: 12px !important;
			}

			.message_mobile_info {
				display: flex;
				align-items: center;
				flex-direction: column;
				position: relative;

				.foot_nav_hover {
					position: absolute;
					left: 40px;
					bottom: 125px;
					padding: 6px;
					display: flex;
					justify-content: center;
					align-items: center;
					background: white;
					img {
						width: 152px;
						height: 152px;
					}
				}

				.message_mobile_icon {
					margin: 30px 15px;
					display: inline-block;
					img {
						width: 25px;
					}
				}
				.message_mobile_wechat {
					img {
						width: 200px;
					}
				}
				.message_mobile_title {
					font-weight: 600;
					font-size: 12px;
					margin-bottom: 12px;
					text-align: center;
				}
				.message_mobile_input {
					display: flex;
					align-items: center;
					font-size: 12px;
					border: 1px solid rgba(150,150,150,0.60);
					border-radius: 2px;

					input {
						flex: 1;
						border: 0;
						height: 28px;
						width: 127px;
						outline: none;
						background: transparent;
						text-align: center;
						letter-spacing: 0;
						color: #bcbcbc;
					}
					
					div {
						width: 52px;
						height: 28px;
						display: flex;
						justify-content: center;
						align-items: center;
						opacity: 0.6;
						color: #FFFFFF;
						letter-spacing: 0;
						letter-spacing: 0;
						background: rgb(150,150,150);
						margin-left: -1px;

					}
				}
			}
		}
	}

	.copy_right {
		width: 100%;
		height: 40px;
		line-height: 24px;
		display: flex;
		justify-content: center;
		align-items: center;
		text-align: center;
		font-size: 14px;
		background: #6C6C6C;
		color: rgba(255, 255, 255, 0.6);
	}
	.copy_right_mobile {
		font-size: 12px;
	}

	input::-webkit-input-placeholder {
		/* WebKit browsers */
		color: #bcbcbc;
		letter-spacing: .5px;
	}

	input:-moz-placeholder {
		/* Mozilla Firefox 4 to 18 */
		color: #bcbcbc;
		letter-spacing: .5px;
	}

	input::-moz-placeholder {
		/* Mozilla Firefox 19+ */
		color: #bcbcbc;
		letter-spacing: .5px;
	}

	input:-ms-input-placeholder {
		/* Internet Explorer 10+ */
		color: #bcbcbc;
		letter-spacing: .5px;
	}
</style>
