/**
 * 该文件将 components下的所有index.vue结尾的文件全部读取出来。
 * 并将组件注册到全局当中
 */

export default {
	install(Vue) {
		const files	 = require.context('@/components', true, /\.vue$/);
		files.keys().forEach(key => {
			const component = files(key).default;
			Vue.component(component.name, component)
		});
	}
}

