
<template>
  <div class="content">
		<!-- <img @click="showHome()" class="logo" src="@/assets/logo_blue.png" /> -->
		<img @click="showHome()" class="logo" src="@/assets/logo_blue_4.png" />
    <div class="menu_info">
      <div class="menu_search" @click="isShowSearch=true">
        <a-icon type="search" :style="{ fontSize: '20px', color: '#000' }"/>
      </div>
      <div class="drop_content">
        <a-dropdown :trigger="['click']">
					<div class="single_drop">
            <a-icon  type="menu" :style="{ fontSize: '20px', color: '#000' }"/>
					</div>
					<template #overlay>
						<div class="single_drop_content">
							<template v-for="(item, index) in menuList">
								<div class="single_drop_info" :key="index" @click="showPage(item)">
									<div>{{$t('nav.' + item)}}</div>
								</div>
							</template>
             <!-- <div class="single_drop_info" @click="changeLan">
                <a-icon type="global" :style="{ fontSize: '20px', color: '#bcbcbc' }"/><span>{{lanText}}</span>
              </div> -->
						</div>
					</template>
				</a-dropdown>
      </div>
    </div>

    <div class="search_content" @click="isShowSearch=false" v-if="isShowSearch">
      <div class="search_head" @click.stop>
        <div class="input_info">
          <input v-model="searchText" @keyup.enter="onSearch()" :placeholder="$t('nav.search')"/>
        </div>
        <div class="search_btn" @click="onSearch()">
          <a-icon type="search" :style="{ fontSize: '20px', color: '#000' }"/>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { mapActions } from 'vuex';
import common from '@/mixin/common.js';
export default {
  name: 'r-header-mobile',
  props: {
    menuList: {
      type: Array,
      default: []
    }
  },
  mixins: [common],
  data() {
    return {
      isShowSearch: false,
      searchText: null,
    }
  },
  mounted() {

  },
  methods: {
		...mapActions('language', ['changeLanguage']),
    showPage(params) {
			this.$router.push('/' + params);
    },
    changeLan() {
      // this.changeLanguage(this.zhcn ? 'en' : 'zh');
	  this.changeLanguage('en');
    },
    onSearch() {
      if (!this.searchText) return;
      this.isShowSearch = false;
      this.$router.push('/search/' + encodeURIComponent(this.searchText));
    },
    showHome() {
			this.$router.push('/');
    }
  },
  computed: {
    lanText: function() {
      // return this.zhcn ? 'EN' : '�
	  return 'EN'
    }
  }
}
</script>
<style lang="scss" scoped>
.content {
  width: 100%;
  height: 60px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 40px;
  .logo {
    height: 41px;
  }
  .menu_info {
    display: flex;
    align-items: center;
    height: 100%;
    cursor: pointer;
    .menu_search {
      height: 70%;
      width: 40px;
      display: flex;
      align-items: center;
      justify-content: center;
    }
    .drop_content {
      height: 100%;
      .single_drop {
        height: 100%;
        height: 100%;
        display: flex;
        align-items: center;
        margin-left: 10px;
      }
    }
  }
}
.single_drop_content {
  width: 140px;
  border-top: 2px solid #407FC9;
  background: #FFFFFF;
  box-shadow: 0 1px 2.5px 0 rgba(0,0,0,0.20);
  margin-top: -4px;
  padding: 0 12px;
  font-size: 15px;
  line-height: 21px;
  letter-spacing: 0;
  color: #666666;

  .single_drop_info {
    padding: 12px 0;
    display: flex;
    align-items: center;
    background: white;
    border-bottom: 0.5px solid rgba(151,151,151,0.40);;
    cursor: pointer;
    span {
      margin-left: 10px;
    }
  }
  .single_drop_info:last-child {
    border: 0;
  }
}
.search_content {
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.65);
  z-index: 1;
  .search_head {
    display: flex;
    align-items: center;
    width: 100%;
    height: 70px;
    background: white;
    display: flex;
    .input_info {
      height: 100%;
      display: flex;
      align-items: center;
      flex: 1;
      margin-left: 20px;
      input {
        width: 100%;
        height: 40px;
        border-radius: 10px;
        border: 1px solid black;
        padding-left: 10px;
				outline: none;
        display: flex;
        align-items: center;
      }
    }
    .search_btn {
      width: 60px;
      height: 100%;
      display: flex;
      align-items: center;
      justify-content: center;

    }
  }
}
</style>