export default {
	zh: {
    responsibility:"工作职责",
    jobrequire:"工作要求",
    send:"投递方式",
    sendDes:"请将职位名称/编号、姓名作为邮件标题，投递简历致我司招聘邮箱。",
    applay:"申请"
  },
  en: {
    responsibility:"Responsibilities",
    jobrequire:"Qualifications",
    send:"How to apply",
    sendDes:"Send your resume to our recruitment email address with job position/job position number and your name in the subject line.",
    applay:"Apply"
  }
}