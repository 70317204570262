/**
 * 该文件将当前文件夹下的所有.js结尾的文件全部读取出来。
 * 并导出去
 */

import VueI18n from 'vue-i18n'
import Vue from 'vue';
import * as cookie from '@/util/cookie.js';

Vue.use(VueI18n)

const files = require.context('.', true, /\.js$/)
const messages = {
	zh: {},
	en: {}
}

files.keys().forEach(key => {
	// 过滤本身
	if (key === './index.js') return;
	// 切割key的首尾
	const temp = key.replace('./', '').replace('.js', '').split('/');
	const count = temp.length;
	// 如果有子文件夹，导入文件夹名字和文件夹下的 index.js文件
	if (count > 1 && temp[count - 1] !== 'index') return;
	const config = files(key).default;
	messages.zh[temp] = config.zh;
	messages.en[temp] = config.en;
});

if (process.env.NODE_ENV === 'development') {
  console.log(' == language ==', messages)
}

const getLocale = () => {
	const key = 'alpsentekchkey';
	const result = cookie.getCookie(key);
	// return result || 'zh';
	return result || 'en'
}
const i18n = new VueI18n({
  locale: getLocale(),
  messages
})
export default i18n
