export default {
	zh: {
    // addressInfoName1: '深圳',
    // addressInfoDetail1: '中国深圳市南山区南山智园崇文园<br/>3号楼2001',
    // addressInfoName2: '北京',
    // addressInfoDetail2: '中国北京市海淀区花园北路25号<br/>EPARK2号楼一层137',
    // addressInfoName3: '南京',
    // addressInfoDetail3: '中国南京市雨花台区软件大道<br/>109号3栋1208',
    addressInfoName4: '苏黎世',
    addressInfoDetail4: 'Hagenholzstrasse 85A，<br/>8050 Zurich，Switzerland',
    scan: '扫一扫关注锐思智芯',
    getMoreInfo: '微信公众号了解更多',
    footInfoTitle1: '<span>商务垂询<span>',
    footInfoTitle2: '<span>简历投递<span>',
    footInfoTitle3: 'Linkedin <span>页面<span>',
  },
  en: {
    // addressInfoName1: 'Shenzhen',
    // addressInfoDetail1: 'A-1018, Block 10, Shenzhen Bay<br/>Science and Technology Ecological Park,<br/>Nanshan District, Shenzhen, China',
    // addressInfoName2: 'Beijing',
    // addressInfoDetail2: '0-2-223, No. 25,<br/>Huayuanbei Street, Haidian District,<br/>Beijing, China',
    // addressInfoName3: 'Nanjing',
    // addressInfoDetail3: '1208, Building 3, No. 109,<br/> software Avenue, Yuhuatai district, Nanjing, China',
    addressInfoName4: 'Zurich',
    addressInfoDetail4: 'Hagenholzstrasse 85A,<br/>8050 Zurich，Switzerland',
    scan: 'Scan QR code',
    getMoreInfo: 'to follow our official<br/>WeChat account',
    footInfoTitle1: 'Contact Us for Business',
    footInfoTitle2: 'Submit Your CV',
    footInfoTitle3: 'Linkedin Page',
  }
}