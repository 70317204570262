export default {
	zh: {
    title1: "手机",
    title2: "大屏式智能设备",
    title3: "智能家居设备",
    title4: "机器人",
    title5: "汽车",
    title6: "安防监控",
    more: "了解更多"
  },
  en: {
    title1: "Smartphones",
    title2: "Big-screen Smart Devices",
    title3: "Smart Homes",
    title4: "Robotics",
    title5: "Automobiles",
    title6: "Surveillance",
    more: "Learn More"
  }
}