const error = [{
	"ErrCode": "1004",
	"error_message": "参数不能为空",
	"message": "参数不能为空"
}, {
	"ErrCode": "1005",
	"error_message": "参数格式不正确",
	"message": "参数格式不正确"
}, {
	"ErrCode": "401",
	"error_message": "用户未授权",
	"message": "用户未授权"
}]


export default error;
