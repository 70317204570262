export default {
	zh: {
    productInfoTitle: 'Hybrid Vision技术',
    productInfoTitle1: '传统图像传感器原理',
    productInfoDes1: '传统图像传感器虽然成像优质, 但设计源点是为适应人眼而非机器，它的图像采集方式是以固定频率 “帧”为基础，帧之间都存在信息缺失，无法进行连续记录，并造成机器的大量数据重复和冗余；而且其曝光操作方式也决定了动态范围小，无法适应极端光照环境的缺陷。因而不具备机器视觉所要求的快速响应，低系统延时，高环境适应能力等要素。',
    productInfoDes1Mobile: '传统图像传感器虽然成像优质, 但设计源点是为适应人眼而非机器，它的图像采集方式是以固定频率 “帧”为基础，帧之间都存在信息缺失，无法进行连续记录，并造成机器的大量数据重复和冗余；而且其曝光操作方式也决定了动态范围小，无法适应极端光照环境的缺陷。因而不具备机器视觉所要求的快速响应，低系统延时，高环境适应能力等要素。',
    productInfoTitle2: '仿生视觉传感器原理',
    productInfoDes2: '仿生视觉传感器基于事件进行驱动，模仿人眼视网膜神经元的工作原理，仅当感知到变化时以极快的响应速度，将事件信号进行记录和输出，得以达到低功耗地连续感知场景变化的效果；同时可自动调节参数以适应各种光照条件。',
    productInfoDes2Mobile: '仿生视觉传感器基于事件进行驱动，模仿人眼视网膜神经元的工作原理，仅当感知到变化时以极快的响应速度，将事件信号进行记录和输出，得以达到低功耗地连续感知场景变化的效果；同时可自动调节参数以适应各种光照条件。',
    productInfoSubTitle: '针对传统视觉传感器的发展瓶颈应运而生，是融合仿生视觉和传统图像传感器优势的新一代机器视觉技术。',
    productDesTitle: 'Hybrid Vision <span>——</span> 融合式仿生视觉技术',
    productDesTitleMobile: 'Hybrid Vision <span>—</span> 融合式仿生视觉技术',
    vidoeText: 'Hybrid Vision <span>——</span> 融合式仿生视觉技术运作效果',
    vidoeTextMobile: 'Hybrid Vision <span>—</span> 融合式仿生视觉技术运作效果',
    productDes1:"集仿生视觉技术与传统图像传感器于一体，既能够模仿人眼视网膜神经元的工作原理，基于事件进行驱动，以微秒级响应速度，全时域捕捉变化的信息；又继承了传统图像传感器的技术优势，在影像拍摄领域保证了全幅画质和影像细节，以达到一个芯片同时输出高质量影像和事件流数据的效果。",
    productDes2:"该技术，从源头消除冗余数据，极大程度减少系统数据处理量，降低系统成本；并自动调节参数以适应当前光照条件，以达到高动态范围，确保在极端以及明暗快速变换的光照条件下保持良好运行；同时和传统图像传感器制程相同，兼容现有图像处理算法及架构，可快速适配并应用于实际工作场景中。",
    productDes3:"在源于自主专利技术的革新下， 锐思智芯 Hybrid Vision —— 融合式仿生视觉技术赋能的传感器，使机器具有更低的系统延时、更快速的响应能力、更优的暗光性能、更高的信噪比、以及更低的成本等一系列优势。",
  },
  en: {
    productInfoTitle: 'Hybrid Vision Technology',
    productInfoTitle1: 'Traditional Image Sensors',
    productInfoDes1: 'Although traditional image sensors can produce high-quality images, they are designed for human eyes rather than for machines. Image collection relies on frames at fixed frequencies. The missing information between two frames results in discontinuous recording, data duplication, and redundancy.<br/><br/>In addition, the limited dynamic range and the limited capacity to adapt to extreme lighting conditions are determined by the exposure mechanism. Traditional image sensors are consequently inadequate<br/>in meeting the demands of machine vision for fast response, low system latency, and high environmental adaptability.',
    productInfoDes1Mobile: 'Although traditional image sensors can produce high-quality images, they are designed for human eyes rather than for machines. Image collection relies on frames at fixed frequencies. The missing information between two frames results in discontinuous recording, data duplication, and redundancy.<br/><br/>In addition, the limited dynamic range and the limited capacity to adapt to extreme lighting conditions are determined by the exposure mechanism. Traditional image sensors are consequently inadequate in meeting the demands of machine vision for fast response, low system latency, and high environmental adaptability.',
    productInfoTitle2: 'Biomimetic Vision Sensors',
    productInfoDes2: 'Biomimetic vision sensors are event-driven, mimicking the working principle of the retinal neurons in human eyes.<br/><br/> They employ rapid responses and low power consumption to record and output event signals only when they sense a change for continuous environment monitoring. They can also automatically adjust parameters to adapt to diverse lighting conditions.',
    productInfoDes2Mobile: 'Biomimetic vision sensors are event-driven, mimicking the working principle of the retinal neurons in human eyes.<br/><br/> They employ rapid responses and low power consumption to record and output event signals only when they sense a change for continuous environment monitoring. They can also automatically adjust parameters to adapt to diverse lighting conditions.',
    productInfoSubTitle: 'This next-generation technology for machine vision addresses the bottleneck of traditional<br/>vision sensors by combining the advantages of biomimetic vision and traditional imaging.',
    productDesTitle: 'Hybrid Vision — Hybrid Biomimetic Vision Technology',
    productDesTitleMobile: 'Hybrid Vision — Hybrid Biomimetic Vision Technology',
    vidoeText: 'Hybrid Vision - Applications of Hybrid Biomimetic Vision',
    vidoeTextMobile: 'Hybrid Vision - Applications of Hybrid Biomimetic Vision',
    productDes1:"This technology combines biomimetic vision with traditional image sensors to mimic the working principle of the retinal neurons in human eyes. Information capture is driven by events, responding in microseconds and covering the time domain of the full event. Hybrid Vision uses a single chip but retains the technical advantage of traditional image sensors for full resolution with ultra-high image quality and seamless event streaming.",
    productDes2:"Redundant data is eliminated at the source for dramatic reductions in data processing volume and system costs. Automatic adjustment of parameters adapts to existing lighting conditions for a high dynamic range and continuous operations during extreme or rapidly changing lighting conditions. Hybrid vision sensors match traditional image sensors with the same process to provide compatibility with existing algorithms and architecture for image processing and quick adaptation for actual application scenarios.",
    productDes3:"Sensors with AlpsenTek Hybrid Vision provide a wide variety of advantages, including lower system latency, faster response, better low-light performance, higher SNR (Signal to Noise Ratio), and lower cost.",
  }
}