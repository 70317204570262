import {
	mapGetters
} from 'vuex';
// 深度合并数据
export default {
	data() {
		return {}
	},
	mounted() {
	},
	methods: {
		// scrollToTop() {
		// 	setTimeout(()=>{
		// 		this.$nextTick(()=>{
		// 			window.scrollTo({"behavior":"smooth","top": 0});
		// 		});
		// 	},10);
		// }
	},
	watch: {
		// 系统语言发生变化
		// zhcn: {
		// 	handler: function(newVal) {
		// 		try {
		// 			// 设置i18n的语言类型
		// 			this.$i18n.locale = newVal ? 'zh' : 'en';
		// 			document.title = this.$t('home.htmlTitle');
		// 			// 修改页面的文字
		// 			this.changePageLanguage();
		// 		} catch (e) {
		// 			// 页面中没有实现changePageLanguage的方法
		// 		}
		// 	},
		// 	deep: true,
		// }
	},

	computed: {
		...mapGetters('language', ['zhcn', 'zhPageKey']),
		...mapGetters(['isMobileBrowse']),
		mobileLanguage() {
			// PC和手机的样式微调
			return this.isMobileBrowse ? 'Mobile' : ''
		},
		mobilePrefix() {
			// PC和手机的样式微调
			return this.isMobileBrowse ? '_mobile' : '_pc'
		},
		zhcnPrefix() {
			// 全局的字体后缀
			// return this.zhcn ? '_cn' : '_en';
			return '_en';
		}
	},
}
