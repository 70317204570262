import { countryList, industryList, productBuy } from './api/api';

export default {
	namespaced: true,
	state: {
		countryList: [], // 购买产品国家列表
		industryList: [], // 购买产品行业列表
	},
	getters: {
		countryList: state => state.countryList,
		industryList: state => state.industryList,
	},
	actions: {
    async getCountryList({ state, dispatch }) {
      if (!state.countryList || !state.length) {
        const option = {
          showError: false,
          opt: Object.assign({}, countryList),
        }
        const result = await dispatch('api/fetchAPI', option, {
          root: true
        });

        if (result.isSuccess) {
          state.countryList = result.data;
        }
      }
    },
    async getIndustryList({ state, dispatch }) {
      if (!state.industryList || !state.length) {
        const option = {
          showError: false,
          opt: Object.assign({}, industryList),
        }
        const result = await dispatch('api/fetchAPI', option, {
          root: true
        });
        if (result.isSuccess) {
          state.industryList = result.data;
        }
      }
    },
    async submitInfo({ dispatch }, params = {}) {
      const option = {
        showError: false,
        opt: Object.assign({}, productBuy, {
          data: Object.assign({}, params)
        }),
      }
      const result = await dispatch('api/fetchAPI', option, {
        root: true
      });
      return result.isSuccess;
    }
	},
}
