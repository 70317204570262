// 最新动态 路由配置
export default {
  path: '/news',
  meta: {
    breadName: 'news'
  },
  component: ()=> import(/* webpackChunkName: "Layout" */ '@/components/layout.vue'),
  children: [
    {
      path:'',
      name: 'news',
      component: () => import( /* webpackChunkName: "News" */ '@/views/news/index.vue'),
    },
    {
      // 应用场景详情
      path: 'detail/:id',
      name: 'newsDetail',
      meta: {
        breadName: 'newsDetail'
      },
      component: () => import(/* webpackChunkName: "NewsDetail" */ '@/views/news/detail.vue'),
    }
  ]
}