export default {
	zh: {
    search: "搜索",
    find:"我们发现",
    unit:"个",
    resultText: "的搜索结果",
    readMore: '查看更多',
    noResult: '暂无搜索结果'
  },
  en: {
    search: "Search",
    find:"Found",
    unit:"",
    resultText: "Results",
    readMore: 'More',
    noResult: 'No Results Found'
  }
}