import axios from 'axios';

class Client {
	constructor() {
		// 请求对象
		this.axios = null;
		// 请求主域名
		this.domain = '';
		// 请求jwt
		this.token = '';

		// 回调方法
		this.showLoading = null;
		this.hideLoading = null;
	}

	getAxios() {
		if (!this.axios) {
			this.axios = axios.create();
		}
		return this.axios;
	}

	getOptions() {
		return {
			method: 'get',
			baseURL: this.domain,
			headers: {
				'content-type': 'application/json'
			},
			timeout: 5000,

			// 这将设置一个 `Authorization` 头，覆写掉现有的任意使用 `headers` 设置的自定义 `Authorization`头
			auth: {
				token: this.getToken()
			},
		}
	}

	setShowLoading(options) {
		if (options && typeof options === 'function') {
			this.showLoading = options
		}
	}

	setHideLoading(options) {
		if (options && typeof options === 'function') {
			this.hideLoading = options
		}
	}

	// 设置请求域名地址
	setDomain(params = null) {
		this.domain = params || '';
		// this.domain = "https://alpsentek.com"
		// this.domain = "https://alpsentek.ch"
	}


	// 设置JWT
	setToken(token = null) {
		this.token = token || '';
	}
	getToken() {
		return this.token;
	}

}

// 添加类目方法
Client.prototype.request = require('./flow.js').default;

// Client.prototype.beforeSend = require('./beforeSend.js').default;
// Client.prototype.req = require('./request.js').default;
// Client.prototype.res = require('./response.js').default;
// Client.prototype.complete = require('./complete.js').default;
// Client.prototype.error = require('./error.js').default;


export default Client;
