<template>
  <!-- 面包屑组件 -->
  <div :class="['bread_content', 'bread_content' + mobilePrefix]">
    <template v-for="(item, index) in originBreadList">
      <div class="single_bread" @click="showPage(item)" :key="index">
        <div v-if="index" class="single_bread_arrow">
          <a-icon type="right" :style="{ fontSize: '13px', color: '#7F7F95' }"/>
        </div>
        <div :class="['single_bread_name', 'single_bread_name' + mobilePrefix]">
          <span v-if="!item.special">{{$t('breadcrumb.' + item.key)}}</span>
          <span v-else>{{item.title}}</span>
        </div>
      </div>
    </template>
  </div>
</template>

<script>
import common from '@/mixin/common.js';
export default {
  mixins: [common],
  name: 'r-breadcrumb',
  data() {
    return {
      originBreadList: []
    }
  },
  created() {
	console.log('this.$route.matched',this.$route.matched)
    const matched = this.$route.matched.filter(item => {
      return item && item.meta && item.meta.breadName;
    });
    const temp = matched.map((item) => {
      return {
        path: item.path,
        key: item.meta.breadName,
        special: false,
      }
    });
    this.originBreadList =  [{
      path: '/',
      key: 'home',
      special: false,
    }].concat(temp);
	console.log('面包屑',this.originBreadList)
  },
  methods:{
    showPage(params) {
      if (params && params.path) {
        this.$router.push(params.path); 
      }
    },
    changeLastBread(params) {
      try {
        if (!params) {
          throw new Error('params null');
        }
        const count = this.originBreadList.length - 1;
        const obj = this.originBreadList[count];
        obj.special = true;
        obj.title = params;
        this.originBreadList.splice(count, 1, obj);
      } catch (error) {
        console.error(' change breadcrumb error ', error);
      }
    }
  },
}
</script>

<style lang="scss" scoped>
.bread_content{
  max-width: $page_max_width;
  display: flex;
  align-items: center;
  padding-top: 15px;

  .single_bread {
    cursor: pointer;
    display: flex;
    color: #7F7F95;
    .single_bread_name {
      margin: 0 20px;
      span {
        font-family: $SourceHanSansCNMedium !important;
        font-size: 14px !important;
        letter-spacing: 1.2px !important;
      }
    }
    .single_bread_name_mobile {
      max-width: 100px;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }
  }
  .single_bread:last-child {
    color: black;
  }
  .single_bread_arrow:nth-last-child(0){
    display: none;
  }
}
.bread_content_pc {
  margin: 0 auto;
  padding: 0 10px;
  height: 70px;
}
.bread_content_mobile {
  margin: 0 auto;
  padding: 30px 0;
}

</style>