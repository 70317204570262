import Vue from 'vue'
import App from './App.vue'
import '@/registerServiceWorker'
import router from '@/router'
import store from '@/store';
Vue.config.productionTip = false;

// 按需引入antd
import '@/util/antd.js';
// 国际化
import i18n from '@/util/language';

// 引入自定义的组件
import components from '@/components';
Vue.use(components);

// 过滤器
import * as filters from '@/util/filter.js'
Object.keys(filters).forEach(key => {
  Vue.filter(key, filters[key])
});


Vue.prototype.toUpperCase = (params) => {  
	if (!params) return;
  return params.toUpperCase();
}


import '@/assets/css/common.scss';


new Vue({
	router,
	store,
	i18n,
	render: h => h(App)
}).$mount('#app')
