import { sceneArticleList } from './api/api';

export default {
	namespaced: true,
	state: {
		sceneList: [
			{ id: 1, key: 'mobile-phone', bg: require('@/assets/scene/bg_phone.png'), img: require('@/assets/scene/img_phone.png') },
			{ id: 2, key: 'large-screen-equipment', bg: require('@/assets/scene/bg_screen.png'), img: require('@/assets/scene/img_screen.png') },
			{ id: 3, key: 'smart-home', bg: require('@/assets/scene/bg_house.png'), img: require('@/assets/scene/img_house.png') },
			{ id: 4, key: 'robot', bg: require('@/assets/scene/bg_robot.png'), img: require('@/assets/scene/img_robot.png') },
			{ id: 5, key: 'automobile', bg: require('@/assets/scene/bg_car.png'), img: require('@/assets/scene/img_car.png') },
			{ id: 6, key: 'security-monitoring', bg: require('@/assets/scene/bg_monitor.png'), img: require('@/assets/scene/img_monitor.png') },
		],
		articleList: [], // 应用场景详情里面的 相关文章
	},
	getters: {
		sceneList: state => state.sceneList,
		articleList: state => state.articleList,
	},
	actions: {
    async getArticleList({ state, dispatch }) {
      const option = {
				showError: false,
				opt: Object.assign({}, sceneArticleList),
			}
			const result = await dispatch('api/fetchAPI', option, {
				root: true
			});
      if (result.isSuccess && result.data) {
        state.articleList = result.data;
      }
    },
	},
}
