export default {
	zh: {
    chip: "芯片",
    parameters: "参数详情",
    buy: "购买",
    coming: "即将上市",
    solution: "解决方案",
    chiptitle1:"ALPIX-Titlis融合式低功耗仿生视觉传感器",
    chiptitle1Mobile:"ALPIX-Titlis<br/>融合式低功耗仿生视觉传感器",
    chipdes1:"适用于各类视觉IoT场景，在保持对场景连续感知的同时降低系统功耗。<br/>像素端过滤80%无效信息，帮助提高有效信息占比，节省系统成本，为各类端侧的人工智能<br/>场景量身打造；在适配现有图像系统基础上，增加独立的事件流数据，帮助提高算法的效率<br/>及精度。",
    chipdes1Mobile:"适用于各类视觉IoT场景，在保持对场景连续感知的同时降低系统功耗。像素端过滤80%无效信息，帮助提高有效信息占比，节省系统成本，为各类端侧的人工智能场景量身打造；在适配现有图像系统基础上，增加独立的事件流数据，帮助提高算法的效率及精度。",
    parameter11:"分辨率：1080p",
    parameter12:"深度优化暗光环境性能: <0.05 lux",
    parameter13:"RGB滤镜",
    parameter14:"优异的信噪比",
    parameter15:"图像模式为卷帘快门",
    parameter16:"信号频域：ca. 10Hz ~ 1kHz",
    parameter17:"RGB成像模式和仿生视觉模式切换",
    parameter18:"可调的对比敏感度及信号带宽，更强的<br/>系统和应用场景的适配性",
    parameter19:"亚毫秒级响应时间",
    parameter110:"动态范围: 120dB",
    chiptitle2:"ALPIX-Eiger融合式高端仿生视觉传感器",
    chiptitle2Mobile:"ALPIX-Eiger<br/>融合式高端仿生视觉传感器",
    chipdes2:"适用于超高画质拍摄的小型相机设备。在保证业内最高分辨率的同时，为行业带来最小的像<br/>素大小。<br/>高质量的传统图像数据在高信噪比、高帧率、高动态范围的事件流数据同步加持下，进一步<br/>提高拍摄的成像质量；基于事件流数据，为图像信号提供实时的算法处理，实现相机设备更<br/>优的视觉功能。",
    chipdes2Mobile:"适用于超高画质拍摄的小型相机设备。在保证业内最高分辨率的同时，为行业带来最小的像素大小。高质量的传统图像数据在高信噪比、高帧率、高动态范围的事件流数据同步加持下，进一步提高拍摄的成像质量；基于事件流数据，为图像信号提供实时的算法处理，实现相机设备更优的视觉功能。",
    parameter21:"分辨率：超过800万",
    parameter22:"帧率: 等效于2万帧/秒",
    parameter23:"RGB 滤镜",
    parameter24:"动态范围：120dB",
    parameter25:"图像模式可设置为全局快门或卷帘快门",
    parameter26:"优异的暗光环境性能：<0.1 lux",
    parameter27:"RGB数据和仿生视觉数据同时输出",
    parameter28:"优异的信噪比",
    parameter29:"RGB成像性能与高端的传统图像传感器一致",
    solutiontitle1:"开发工具库",
    solutiondes1:"提供针对 ALPIX 融合式仿生视觉方案定制的驱动、算法、源代码和应用示例。<br/>帮助客户快速、全面地应用 ALPIX 产品, 提升其视觉系统的整体性能，缩短整<br/>个开发周期。<br/><br/>根据客户需求，为其应用场景和平台定制所需的SDK。",
    solutiontitle2:"参考设计",
    solutiondes2:"基于 ALPIX 融合式仿生视觉方案，提供定制化视觉系统，包括:<br/> MCU，CPU，NPU，GPU等处理模块；<br/>USB，SPI，Bluetooth，WIFI，Ethernet、MIPI等接口模块；<br/>及存储、显示等功能模块。<br/><br/>结合开发工具库，建构快速开发及测试平台，为客户开发自有系统提供参考。",
    solutiontitle3:"视觉模块",
    solutiondes3:"提供定制化的芯片、光学、PCB、软件、封装、测试等全套视觉模块，助力客<br/>户的视觉方案从像素层到系统层全面领先于市场竞品。",
    formtitle:"提交表格，<br/>我们将根据您的需求尽快与您取得联系：",
    formtitleMobile:"提交表格，我们将根据您的需求<br/>尽快与您取得联系：",
    name:"*姓名",
    email:"*邮箱",
    phone:"电话",
    worktitle:"*工作头衔",
    company:"*公司",
    nation:"*国家／地区",
    industry:"*行业",
    require:"请告诉我们您的需求",
    book:"我想订阅锐思智芯的最新动态",
    submitName: '提交',
    hasSubmitName: '已提交',
    thanks: '感谢',


  },
  en: {
    chip: "Chips",
    parameters: "Specifications",
    buy: "Buy",
    coming: "Coming soon",
    solution: "Solutions",
    chiptitle1:"ALPIX-Titlis<br/>Hybrid Low-Power Sensors with Biomimetic Vision",
    chiptitle1Mobile:"ALPIX-Titlis<br/>Hybrid Low-Power Sensors with Biomimetic Vision",
    chipdes1:"The sensor applies to a variety of IoT scenarios involving vision recognition. It offers continuous scenario monitoring while lowering system power consumption.<br/><br/>The sensor can filter out 80% of invalid information at the pixel level to<br/>increase the proportion of valid information, reduce system costs, and<br/>customize a variety of end-user AI scenarios. It adapts to the existing<br/>image system and includes data from independent event streaming to<br/>increase the efficiency and accuracy of the algorithm.",
    chipdes1Mobile:"The sensor applies to a variety of IoT scenarios involving vision recognition. It offers continuous scenario monitoring while lowering system power consumption.<br/><br/>The sensor can filter out 80% of invalid information at the pixel level to increase the proportion of valid information, reduce system costs, and<br/>customize a variety of end-user AI scenarios. It adapts to the existing<br/>image system and includes data from independent event streaming to<br/>increase the efficiency and accuracy of the algorithm.",
    // parameter11:"Resolution: 1080p",
    // parameter12:"Low-light performance: <0.05 lux",
    // parameter13:"Filter: RGB",
    // // parameter14:"Excellent SNR",
    // parameter15:"Exposure Mode: Rolling shutter",
    // parameter16:"Signal frequency: 10 Hz – 1 kHz",
    // parameter17:"Image Mode: RGB imaging and biomimetic vision",
    // parameter18:"Other: Excellent SNR, adjustable contrast sensitivity and signal bandwidth, and stronger system and application adaptability",
    // parameter19:"Response: <1 millisecond",
    // parameter110:"Dynamic range: 120 dB",
	
	parameter11: "Resolution: APS: 2688 × 1520 (4M)   EVS: 1344 × 760 (1M)",
	parameter12: "Pixel size: 3.15 µm × 3.15 µm",
	parameter13: "Optical format: 1/1.64″, CRA13°",
	parameter14: "Operation mode: Image mode or Event mode",
	parameter15: "EVS contrast sensitivity: Configurable 0% - 100%",
	parameter16: "Shutter mode: Rolling shutter",
	parameter17: "HDR mode: Support multi-frame HDR",
	parameter18: "Min. illumination: < 0.05 lux",
	parameter19: "Frame rate: APS: 60 fps @ Full resolution  EVS: 500 fps @ Full resolution",
	parameter20: "Dynamic range: APS: ~ 68 dB  EVS: ~ 120 dB",
	parameter21: "ROI: APS: support   EVS: support",
	parameter22: "Operating Voltage: Analog: 3.0V-3.3V   Digital: 1.1V    I/O: 1.8V",
	parameter23: "Power consumption:  < 3mW (EVS mode @ VGA 30fps)  150mW (60 fps @ APS full resolution)",
	parameter24: "Data format:  PS: Raw8/Raw10/Raw12",
	parameter25: "Output Interface:  4 lane MIPI & 4 lane LVDS @ APS/EVS    Support 8bit dvp",
	parameter26: "LVDS:  LVDS @ 800 Mbps/lane",
	parameter27: "Package:  CSP",
		
		
    chiptitle2:"ALPIX-Eiger<br/>Hybrid Sensors with High-end Biomimetic Vision",
    chiptitle2Mobile:"ALPIX-Eiger<br/>Hybrid Sensors with High-end Biomimetic Vision",
    chipdes2:"The sensor is customized for small high-resolution cameras, and it provides<br/>the highest resolution with the smallest pixels in the industry.<br/><br/>The sensor employs event streaming with high SNR, high frame rate, and<br/>wide dynamic range to improve the already high quality of traditional images.<br/>The event streaming of the sensor powers a real-time algorithm for processing<br/>image signals, bringing the precision of cameras to a new level.",
    chipdes2Mobile:"The sensor is customized for small high-resolution cameras, and it provides the highest resolution with the smallest pixels in the industry.<br/><br/>The sensor employs event streaming with high SNR, high frame rate, and wide dynamic range to improve the already high quality of traditional images. The event streaming of the sensor powers a real-time algorithm for processing image signals, bringing the precision of cameras to a new level.",
    // parameter21:"Resolution: >8 MP",
    // parameter22:"Frame rate: 20,000/s",
    // parameter23:"Filter: RGB",
    // parameter24:"Dynamic range: 120 dB",
    // parameter25:"Exposure Mode: Rolling shutter and Global shutter",
    // parameter26:"Low-light performance: <0.1 lux",
    // parameter27:"Image Mode: Simultaneous output of RGB and biomimetic vision data",
    // // parameter28:"Excellent SNR",
    // parameter29:"Other: Excellent SNR and RGB imaging performance equivalent to high-end traditional image sensors",
	parameter31: "Resolution:  APS: 3264 × 2448 (8M) EVS: 1632 × 1224 (2M)",
	parameter32: "Pixel size:  1.89 µm × 1.89 µm",
	parameter33: "Optical format:  ½”, CRA 34°",
	parameter34: "Shutter mode:  Rolling shutter",
	parameter35: "EVS Contrast sensitivity:  Conﬁgurable 0% - 100%",
	parameter36: "Min. illumination:  < 0.1 lux",
	parameter37: "Frame rate APS:  30 fps @ Full resolution  EVS: 1000 fps @ Full resolution, 4000fps @ 2 × 2 binning",
	parameter38: "Package:  COB",
	parameter39: "ROI:   APS: support  EVS: support",
	parameter40: "Operating voltage Analog: 3.0 V, 1.8V   Digital: 1.1 V    I/O: 1.8 V",
	parameter41: "Output interface:   2 × 4 lane MIPI, or 1 × 4 lane MIPI with VC mode",
	parameter42: "Dynamic range:  APS: ~ 68 dB EVS: ~ 100 dB",
	parameter43: "Die size:  7.3 × 7.2 mm",

	
	
    solutiontitle1:"Development Toolbox",
    solutiondes1:"The development toolbox includes customized drivers, algorithms, source code, and application examples for the ALPIX hybrid biomimetic vision solution. Customers can quickly apply the full potential of ALPIX products to improve the overall performance of their vision system and shorten the development cycle.<br/><br/>A Software Development Kit (SDK) is available for custom application scenarios and platforms.",
    solutiontitle2:"Reference Designs",
    solutiondes2:"The custom vision system utilizes the ALPIX hybrid biomimetic vision solution and includes modules for:<br/>Processing - MCU, CPU, NPU, and GPU;<br/>Interfacing - USB, SPI, Bluetooth, WiFi, Ethernet, and MIPI;<br/>Functions - Storage, display, etc.<br/><br/>Customers can combine this system with the SDK to quickly build development and test platforms and create references for individualized system development.",
    solutiontitle3:"Vision Modules",
    solutiondes3:"Vision modules keep customer vision solutions one step ahead of<br/>the competition on both the pixel and system levels.<br/><br/>Provided modules include customized chips, optical systems, PCBs, software, packaging, and testing.",
    formtitle:"Please submit your details and we will contact you: ",
    formtitleMobile: "Please submit your details<br/>and we will contact you: ",
    name:"*Name",
    email:"*E-mail",
    phone:"Phone No.",
    worktitle:"*Title",
    company:"*Company",
    nation:"*Location ",
    industry:"*Industry ",
    require:"Your requirements",
    book:" I want to receive email news updates from AlpsenTek",
    submitName: 'Submit',
    hasSubmitName: 'Submitted',
    thanks: 'Thank you.',
  }
}