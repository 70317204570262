// import { articleSearch } from './api/api';
import {
	articleList
} from './api/api';


export default {
	namespaced: true,
	state: {
		articleList: [], // 应用场景详情里面的 相关文章
		page: {
			page: 0,
			size: 9
		},
		haveMore: true,
	},
	getters: {
		articleList: state => state.articleList,
		page: state => state.page,
		haveMore: state => state.haveMore,
	},
	actions: {
		async getArticleList({
			state,
			dispatch
		}, params = {
			text: null,
			pageNum: 1,
			pageSize: 6
		}) {
			var tempUrl = {
				url: articleList.url + `&pageNum=${params.pageNum}&pageSize=${params.pageSize}&articleTitle=${params.text}`,
				method: articleList.method
			}
			const option = {
				showError: false,
				opt: Object.assign({}, tempUrl, {}),
			}
			const result = await dispatch('api/fetchAPI', option, {
				root: true
			});
			console.log('新闻搜索', result);
			if (result.code===200 && result.rows) {
				state.articleList=[]
				state.articleList.push(...result.rows)
				state.haveMore = state.articleList.length < result.total
			  // state.haveMore = result.data.rows.length >= state.page.size;
			  // state.articleList = params.isReset ? result.data.rows : state.articleList.concat(result.data.rows);
			  // state.page.page ++;
			}
		},
	},
}
