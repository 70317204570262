export default {
	zh: {
    htmlTitle: '锐思智芯',
    middleTitle: '智创融合式仿生视觉芯片及一体化机器视觉解决方案',
    middleDes: '在现有传统图像传感器无法满足机器视觉发展时，视觉传感器的技术革命领导者 ——— 锐思智芯应运而生。<br/>我们是成立于 2019 年，设立于北京、南京、深圳和瑞士的全球化科技公司，公司核心专家在图像传感器领域拥有超过15年的技术积累，在仿生视觉领域也拥有近7年的研究经验，突破性地打造了 ALPIX 融合式仿生视觉传感器，<br/>并为行业带来一体化的芯片解决方案，致力于成为领导机器视觉发展的革新力量。',
    middleDesMobile: '在现有传统图像传感器无法满足机器视觉发展时，视觉传感器的技术革命领导者 —— 锐思智芯应运而生。<br/>我们是成立于 2019 年，设立于北京、南京、深圳和瑞士的全球化科技公司，公司核心专家在图像传感器领域拥有超过15年的技术积累，在仿生视觉领域也拥有近7年的研究经验，突破性地打造了 ALPIX 融合式仿生视觉传感器，并为行业带来一体化的芯片解决方案，致力于成为领导机器视觉发展的革新力量。',
    moreBtn: '了解我们更多产品',
    productTitle: 'ALPIX 融合式仿生视觉传感器',
    productTitleMobile: 'ALPIX 融合式仿生视觉<br/>传感器',
    productDes: '基于颠覆性的底层设计理念与 Hybrid Vision 技术<br/>打造的融合传统图像优势和仿生视觉技术的新一代机器视觉传感器',
    productDesMobile: '基于颠覆性的底层设计理念与 Hybrid Vision 技术打造的融合传统图像优势和仿生视觉技术的新一代机器视觉传感器',
    productInfoTitle1: '传统成像',
    productInfoTitle2: '仿生视觉',
    productMore: '了解核心技术',
    productListTitle1: '高动态范围',
    productListDes1: '超过 120dB, 适应各种极端以及<br/>快速变化的光照条件',
    productListTitle2: '高帧率低延时',
    productListDes2: '微秒级的响应时间，超过<br/>1万帧／秒的等效帧率',
    productListTitle3: '低冗余数据',
    productListDes3: '无变化时不输出信号，消除冗余<br/>数据，有效信息占比超过80%，<br/>节约系统算力，降低运作功耗',
    productListTitle4: '多数据同步输出',
    productListDes4: '超越单一性质芯片，实现传统图<br/>像和事件流数据同步输出',
    productListTitle5: '业内最小像素尺寸',
    productListDes5: '尺寸与传统图像传感器的像素一<br/>致，完全适配各种应用场景对尺<br/>寸和成本的要求',
    productListTitle6: '兼容性强大',
    productListDes6: '可生成与传统图像同等质量照片<br/>及视频，兼容现有图像处理算法<br/>及架构，可快速适配应用',
    news: '最新动态',
    newsMore: '尽请期待',
  },
  en: {
    htmlTitle: 'AlpsenTek',
    middleTitle: 'Hybrid Vision Sensors<br/>and Integrated Machine Vision<br/>Solutions',
    middleDes: 'AlpsenTek was founded in 2019 to overcome the weaknesses of traditional image <br/>sensors in adapting tothe development of machine vision. It is now a global leader in <br/>revolutionary technology for vision sensors.<br/></br>The key experts of AlpsenTek have over 16 years of experience in the emerging field </br>of image sensors and nearly 8 years of experience in the cutting-edge field of </br>biomimetic vision technology. This team has produced several ground breaking</br>innovations, which include the ALPIX hybrid vision sensors and an integrated chip <br/>solution.<br/></br>AlpsenTek is committed to becoming a force for change in leading the development</br>of machine vision technology.',
    middleDesMobile: 'AlpsenTek was founded in 2019 to overcome the weaknesses of traditional image sensors in adapting to the development of machine vision. It is now a global leader in revolutionary technology for vision sensors with offices in Beijing,  Nanjing, Shenzhen, and Switzerland.<br/><br/>The key experts of AlpsenTek have over 15 years of experience in the emerging field of image sensors and nearly 7 years of experience in the cutting-edge field of biomimetic vision technology. This team has produced several groundbreaking innovations, which include the ALPIX hybrid biomimetic vision sensors and an integrated chip solution.<br/><br/>AlpsenTek is committed to becoming a force for change in leading the development of machine vision technology.',
    moreBtn: 'About our products',
    productTitle: 'ALPIX Sensors with Hybrid Biomimetic Vision',
    productTitleMobile: 'ALPIX Sensors with Hybrid Biomimetic Vision',
    productDes: 'These next-generation machine-vision sensors utilize Hybrid Vision technology and<br/>a disruptive design philosophy to combine the advantages of traditional imaging with biomimetic vision.',
    productDesMobile: 'These next-generation machine-vision sensors utilize Hybrid Vision technology and a disruptive design philosophy to combine the advantages of traditional imaging with biomimetic vision.',
    productInfoTitle1: 'Traditional Imaging',
    productInfoTitle2: 'Biomimetic Vision',
    productMore: 'About core technology',
    productListTitle1: 'High Dynamic<br/>Range',
    productListDes1: 'The dynamic range exceeds 120 dB for<br/>adaptation to a variety of extreme and<br/>rapidly changing lighting conditions.',
    productListTitle2: 'Low Latency and<br/>High Frame Rates',
    productListDes2: 'The sensors respond in microseconds<br/>and offer a frame rate in excess of<br/>10,000 frames/s.',
    productListTitle3: 'Low<br/>Redundancy',
    productListDes3: 'There is no output of signals when<br/>there are no changes, eliminating the<br/>production of redundant data. The rate<br/>of valid information exceeds 80%,<br/>conserving system computing power<br/>and reducing power consumption.',
    productListTitle4: 'Simultaneous<br/>Multi-source Output',
    productListDes4: 'These sensors surpass single-purpose<br/>chips to achieve simultaneous output<br/>of data for traditional images and event<br/>stream processing.',
    productListTitle5: 'Unmatched<br/>Pixel Size',
    productListDes5: 'The sensors offer the smallest<br/>pixel size in the industry and match the<br/>pixel size of traditional image sensors<br/>to meet diverse size and<br/>cost requirements for various<br/>application scenarios.',
    productListTitle6: 'Powerful<br/>Compatibility',
    productListDes6: 'The quality of sensor photos and<br/>videos matches that of traditional<br/>images. Compatibility with existing<br/>algorithms and architecture for image<br/>processing delivers rapid adaptation to<br/>different applications.',
    news: 'News',
    newsMore: 'Expect more news soon',
  }
}