import {
	articleList,
	articleInfo
} from './api/api';

export default {
	namespaced: true,
	state: {
		newsList: [], // 最新动态列表
		page: {
			page: 0,
			size: 6
		},
		haveMore: true,
		newsInfo: {},
	},
	getters: {
		newsList: state => state.newsList,
		page: state => state.page,
		haveMore: state => state.haveMore,
		newsInfo: state => state.newsInfo,
	},
	actions: {
		async getNewsList({
			state,
			dispatch
		}, params = {
			pageNum: 1,
			pageSize: 6
		}) {
			// articleList.url+=`&pageNum=${params.pageNum}&pageSize=${params.pageSize}`
			let obj = {
				url: `${articleList.url}&pageNum=${params.pageNum}&pageSize=${params.pageSize}`,
				method: articleList.method,
			}
			console.log('obj',obj)
			const option = {
				showError: false,
				opt: Object.assign({}, obj, {
					// data: Object.assign({
					// 	page,
					// 	size
					// }, params)
				}),
			}
			const result = await dispatch('api/fetchAPI', option, {
				root: true
			});
			if (result.code===200 && result.rows) {
				console.log('新闻页面数据',result)
				// state.newsList = params.isReset ? result.data.rows : state.newsList.concat(result.data.rows);
				state.newsList = []
				state.newsList.push(...result.rows)
				// state.newsList = result.rows
				state.haveMore = state.newsList.length < result.total;
				// state.page.page = page;
				// state.page.size = size;
			}
		},
		async getnewsDetail({
			state,
			dispatch
		}, params = null) {
			console.log('id',params)
			state.newsInfo = {};
			if (!params) return;
			const option = {
				showError: false,
				opt: Object.assign({}, articleInfo(params), {
					// data: {
					// 	id: params
					// }
				}),
			}
			const result = await dispatch('api/fetchAPI', option, {
				root: true
			});
			console.log('新闻详情', result)
			if (result.code===200 && result.rows) {
				state.newsInfo = result.rows[0];
			}
		},
	},
}
