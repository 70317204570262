<template>
	<div id="app" :class="['hidde_scroll', 'app' + zhPageKey]">
	<!-- <div id="app" :class="['hidde_scroll', 'app' + '_en']"> -->
		<div :class="['app_head', 'app_head' + mobilePrefix]">
			<component :is="'r-header'"></component>
		</div>
		<div :class="['app_content', 'app_content' + mobilePrefix]">
			<router-view :key="key"/>
			<div ref="footInfo" id="footInfo">
				<component :is="'r-footer'"></component>
			</div>
		</div>
		<a-back-top v-if="visibleBackTop && showBackTop">
			<div :class="['back_top_img', 'back_top_img' + mobilePrefix]">
				<!-- <a-icon type="vertical-align-top" :style="{ fontSize: '40px', color: '#1b2555' }"/> -->
				<img src="@/assets/back_top_icon.png" alt="" srcset="">
			</div>
		</a-back-top>
	</div>
</template>

<script>
	import { mapActions } from 'vuex';
	import common from '@/mixin/common.js';
	export default {
		mixins: [common],
		data() {
			return {
				showBackTop: true,
				visibleList: ['product', 'join', 'joinDetail', 'news', 'newsDetail', 'search', 'sceneDetail']
			}
		},
		created() {

			// 设置域名配置
			this.setConfig();
			// 初始化请求类
			this.initAPI();
			document.title = this.$t('home.htmlTitle');
			window.onscroll = this.scrollEvent;

			// const isMac = /macintosh|mac os x/i.test(navigator.userAgent);
			// if (!isMac) {
			// }
			// // 不是苹果系统，加载苹方字体
			// let style = document.createElement('style');
			// style.type = 'text/css';
			// style.innerText = '@font-face {font-family:SourceHanSansCNSC-Regular;src:url(' + require('@/assets/font/SourceHanSansCN.ttf') + ')};font-display: swap';
			// document.getElementsByTagName('head')[0].appendChild(style);
		},
		methods: {
			...mapActions(['setConfig']),
			...mapActions('api', ['initAPI']),
			...mapActions('language', ['changeLanguage']),
			getQueryString(name) {
				var reg = new RegExp('(^|&)' + name + '=([^&]*)(&|$)', 'i');
				var r = window.location.search.substr(1).match(reg);
				if (r != null) {
					return unescape(r[2]);
				}
				return null;
			},
			scrollEvent(e) {
				this.$nextTick(()=>{
					try {
						const bodyOffset = document.body.scrollTop || document.documentElement.scrollTop;
						const offset = this.$refs.footInfo.offsetTop - (document.body.clientHeight || document.documentElement.clientHeight);
						const isFoot = !(offset <= bodyOffset);
						const obj = this.visibleList.find((item) => {
							return item === this.$route.name;
						})
						if (obj) {
							this.showBackTop = isFoot && (bodyOffset > 1200)
						} else {
							this.showBackTop = isFoot
						}
					} catch (error) {}
				})
			}
		},
		computed: {
			key() {
				return this.$route.path;
			},
			visibleBackTop() {
				const obj = this.visibleList.find((item) => {
					return item === this.$route.name;
				})
				return obj ? true : false;
			}
		},
	}
</script>


<style lang="scss">

	@font-face {
		font-family: $SourceHanSansCNRegular;
		src: url('./assets/font/SourceHanSansCN-Regular.otf') format('truetype');
		font-weight: normal;
		font-style: normal;
	}

	@font-face {
		font-family: $SourceHanSansCNMedium;
		src: url('./assets/font/SourceHanSansCN-Medium.otf') format('truetype');
		font-weight: normal;
		font-style: normal;
	}

	@font-face {
		font-family: $SourceHanSansCNBold;
		src: url('./assets/font/SourceHanSansCN-Bold.otf') format('truetype');
		font-weight: normal;
		font-style: normal;
	}

	* {
		font-family: $SourceHanSansCNRegular;
	}


	.app_mobile {
		position: fixed;
		left: 0px;
		top: 0px;
		width: 100%;
		height: 100%;
		overflow-y: scroll;
		overflow-x: hidden;
		display: flex;
		flex-direction: column;
	}
	.app_head_pc {
		height: 88px;
	}
	.app_head_mobile {
		height: 60px;
	}
	.app_content_pc {
		margin-top: 88px;
	}
	.app_content_mobile {
		margin-top: 50px;
	}
	#app {
		overflow-x: hidden;
		display: flex;
		flex-direction: column;
		.app_head {
			position: fixed;
			left: 0;
			top: 0;
			background: white;
			// border-bottom: 2px solid #ececec;
			display: flex;
			align-items: center;
			width: 100%;
			z-index: 4;
		}
		
		.app_content {
			width: 100%;
			background: white;
		}
		.back_top_img {
			// width: 100%;
			// margin-left: 10px;
			width: 32px;
			height: 64px;
			img {
				width: 100%;
			}
		}
		.back_top_img_pc {
			margin-left: 50px;
		}
		.back_top_img_mobile {
			margin-left: 10px;
		}
	}
</style>
