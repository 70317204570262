export default {
	zh: {
    productTitle:"Open Positions",
    productSubTitle:"加入我们，一起成为引领机器视觉的革新力量",
    searchPosition:"查找岗位",
    moreText:"了解更多",
    morePosition:"更多职位",
    department: '全部部门',
    address: '任意地点',
    fullTime: '全职',
    partTime: '兼职',
    apply: '申请',
    responsibility:"工作职责",
    jobrequire:"工作要求",
    send:"投递方式",
    sendDes:"请将职位名称/编号、姓名作为邮件标题，投递简历至我司招聘邮箱。",
    publish: '发布',
    noResult: '暂无数据'
  },
  en: {
    productTitle:"Open Positions",
    productSubTitle:"Join AlpsenTek to become a force<br/>for change in leading the development of machine vision.",
    searchPosition:"Search",
    moreText:"Learn More",
    morePosition:"More Positions",
    department: 'Departments',
    address: 'Locations',
    fullTime: 'Full-time',
    partTime: 'Part-time',
    apply: 'Apply',
    responsibility:"Responsibilities",
    jobrequire:"Qualifications",
    send:"How to apply",
    sendDes:"Send your resume to our recruitment email address with job position/job position number and your name in the subject line.",
    publish: 'Posted on',
    noResult: 'No Found'
  }
}