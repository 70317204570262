var language = 'en'


// 获取视频地址
// exports.videoPath = {
// 	url: '/api/technology/video',
// 	method: 'get',
// }
exports.videoPath = {
	url: `/appBanner/getBannerList?spaceId=18&language=${language}`,
	method: 'get',
}


// 订阅消息 发送邮箱----------------（暂未更改）
exports.subscribeEmail = {
	url: '/api/subscribe/info',
	method: 'post',
}


/*
首页 - 头图列表
*/
// exports.bannerList = {
// 	url: '/api/banner/list',
// 	method: 'get',
// };
// 新
exports.bannerList = {
	url: `/appBanner/getBannerList?spaceId=8&language=${language}`,
	method: 'get',
};

/*
最新动态 - 新闻列表
{
    "page": 1,
    "size": 10
}
*/
// exports.articleList = {
// 	url: '/api/news/article/list',
// 	method: 'post',
// };
// 新
// newList/list?parentId=5&pageNum=1&pageSize=6&language=cn
exports.articleList = {
	url: `/newList/list?language=${language}`,
	method: 'get',
};

/*
最新动态 - 新闻详情详情
{
    "id": "1"
}
*/
exports.articleInfo = function(code) {
	return {
		url: `/newList/list?language=${language}&code=${code}`,
		method: 'get'
	}
};

/*
最新动态 - 搜索
{
    "page": 1,
    "size": 10,
    "text": "1"
}
*/
// 暂未接口
exports.articleSearch = {
	url: '/api/news/article/search',
	method: 'post',
};


/*
加入我们 - 招聘列表(岗位)
{
    "page": 1,
    "size": 10,
}
*/
// recruit/list?name=&department=&pageNum=1&pageSize=6&city=&language=en
// exports.jobList = {
// 	url: '/api/career/job/list',
// 	method: 'post',
// };
exports.jobList = {
	url: `/recruit/list?language=${language}`,
	method: 'get'
};


/*
加入我们 - 招聘信息详情
{
    "id": "1"
}
*/
// exports.jobInfo = {
// 	url: '/api/career/job/info',
// 	method: 'post',
// };
exports.jobInfo = {
	url: `/recruit/list?language=${language}`,
	method: 'get',
};

/*
加入我们 - 部门列表
*/
// exports.departmentList = {
// 	url: '/api/career/department/list',
// 	method: 'get',
// };
exports.departmentList = {
	url: `/dict/type/recruit_department_${language}`,
	method: 'get',
};

/*
加入我们 - 城市列表
*/
// exports.cityList = {
// 	url: '/api/career/city/list',
// 	method: 'get',
// };
exports.cityList = {
	url: `/dict/type/recruit_city_${language}`,
	method: 'get',
};

/*
购买产品 - 国家列表 (修改为静态)
*/
// exports.countryList = {
// 	url: '/api/product/country/list',
// 	method: 'get',
// };


/*
购买产品 - 行业列表 (修改为静态)
*/
// exports.industryList = {
// 	url: '/api/product/industry/list',
// 	method: 'get',
// };


/*
购买产品 - 产品列表
*/
// (目前为静态)
exports.productList = {
	url: '/api/product/info/list',
	method: 'get',
};

/*
购买产品 - 购买申请
{
    "name": "测试",
    "email": "test@qq.com",
    "phone": "13000000000",
    "position": "销售经理",
    "company": "上海XXXX公司",
    "requirement": "",
    "subscribe": false,
    "country_id": "1",
    "industry_id": "1",
    "product_id": "1"
}
*/
//(待修改 暂无接口)
exports.productBuy = {
	url: '/api/product/buyer',
	method: 'post',
};



// 应用场景的相关文章 （暂未更改）
exports.sceneArticleList = {
	url: '/api/news/article/similar',
	method: 'get',
}
