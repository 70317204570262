import Vue from 'vue';
import Vuex from 'vuex';
import modules from './modules';

Vue.use(Vuex);

// 和antd的a-col的sm的宽度一样，项目中如果屏幕低于768这个阀值，则视为是手机屏幕
const smallSize = 768;

const store = new Vuex.Store({
	state: {
		domain: '',
		isMobileBrowse: false,  // 是否是手机屏的浏览器。用于区分当前宽度是手机还是PC
	},
	getters: {
		domain: state => state.domain,
		isMobileBrowse: state => state.isMobileBrowse,
	},
	actions: {
		setConfig({
			state,
			dispatch
		}) {

			// 判断是否在正式环境，如果是 production 和地址没有带UAT的，视为正式环境
			const debug = !(process.env.NODE_ENV === 'production' && window.location.href.indexOf('uat') === -1);
			// 设置环境依照根目录下的 .env.development 和 .env.production 环境来区分

			state.domain = debug ? '/api' : (process.env.VUE_APP_BASE_API || '');
			console.log("1111",state.domain);
			// 设置网页语言
			// dispatch('language/initLanguage');
			// dispatch('language')
			dispatch('listeneBrowseSize');
		},
		listeneBrowseSize({ state }) {
			// 监听屏幕自适应
			// 浏览器大小改变的时候，回调方法
			const change = () => {
				const currentSize = document.body.clientWidth;
				state.isMobileBrowse = currentSize <= smallSize;
			}
			window.addEventListener('resize', change, false);
			change();
		}
		
	},
	modules
})

export default store;
