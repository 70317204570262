export default {
  path: '/join', // 加入我们
  meta: {
    breadName: 'join'
  },
  component: ()=> import(/* webpackChunkName: "Layout" */ '@/components/layout.vue'),
  children: [
    { 
      path:'',
      name: 'join',
      component: () => import( /* webpackChunkName: "Join" */ '@/views/join/index.vue'),
    },
    {
      // 加入我们详情
      path: 'detail/:id',
      name: 'joinDetail',
      meta: {
        breadName: 'joinDetail'
      },
      component: () => import(/* webpackChunkName: "JoinDetail" */ '@/views/join/detail.vue'),
    }
  ]
}