// 应用场景路由配置
export default {
  path: '/scene',
  meta: {
    breadName: 'scene'
  },
  component: ()=> import(/* webpackChunkName: "Layout" */ '@/components/layout.vue'),
  children: [
    { 
      path:'',
      name: 'scene',
      component: ()=> import(/* webpackChunkName: "Scene" */ '@/views/scene/index.vue'),
    },
    {
      // 应用场景详情
      path: 'detail',
      name: 'sceneDetail',
      meta: {
        breadName: 'sceneDetail'
      },
      component: () => import(/* webpackChunkName: "SceneDetail" */ '@/views/scene/detail/index.vue'),
    }
  ]
}