
export default {
	zh: {
		record: '© Copyright AlpsenTek 2024. All Right Reserved.',
		// addressInfoTitle1: '北京',
  //   addressInfoDes1: '中国北京市海淀区花园北路25号EPARK2号楼一层137',
		// addressInfoTitle2: '深圳',
  //   addressInfoDes2: '中国深圳市南山区南山智园崇文园3号楼2001',
		// addressInfoTitle3: '南京',
		// addressInfoDes3: '中国南京市雨花台区软件大道109号3栋1208',
		addressInfoTitle1: '苏黎世',
		addressInfoDes1: 'Hagenholzstrasse 85A，8050 Zurich，Switzerland',
		messageInfoTitle1: '联系电话: <br />中国 +86 75526911746<br />苏黎世 +41 43 299 69 70',
		messageInfoTitle2: '联系邮箱: alpsentek@alpsentek.com',
		messageInfoTitle3: '简历投递邮箱: talent@alpsentek.com',
		footTitle: '快速导航',
		footSubMessage: '订阅最新消息',
		emailPlacehoder: '在此输入你的邮箱',
		send: '发送',
		home: '首页',
		news: '新闻中心',
		technology: '核心技术',
		about: '关于我们',
		scene: '应用场景',
		join: '加入我们',
		product: '购买',
		contact: '联系我们',
	},
	en: {
		record: '2019-2024 AlpsenTek GmbH, All Rights Reserved.',
		// addressInfoTitle1: 'Beijing',
		// addressInfoDes1: '0-2-223, No. 25, Huayuanbei Street, Haidian District,<br/>Beijing, China',
		// addressInfoTitle2: 'Shenzhen',
		// addressInfoDes2: '2001, Building 3, Chongwen Park, Nanshan intellectual Park, Nanshan District, Shenzhen, China',
		// addressInfoTitle3: 'Nanjing',
		// addressInfoDes3: '1208, Building 3, No. 109, software Avenue, Yuhuatai District,Nanjing, China',
		addressInfoTitle1: 'Zurich',
		addressInfoDes1: 'Hagenholzstrasse 85A，8050 Zurich，Switzerland',
		messageInfoTitle1: 'Phone No.<br />Switzerland +41 43 299 69 70',
		// messageInfoTitle2: 'E-mail: alpsentek@alpsentek.com',
		messageInfoTitle2: 'E-mail for business: business@alpsentek.com',
		messageInfoTitle3: 'E-mail for CV: talent@alpsentek.com',
		footTitle: 'Site Map',
		footSubMessage: 'Subscribe for News And Updates',
		emailPlacehoder: 'E-mail',
		send: 'Submit',
		home: 'Home',
		news: 'News',
		technology: 'Technology',
		about: 'About Us',
		scene: 'Applications',
		join: 'Careers',
		product: 'Products',
		contact: 'Contact',
	},
}
