<template>
  <div>
    <router-view :key="key"/>
  </div>
</template>

<script>
export default {
  name: 'r-layout',
  computed: {
    key() {
      return this.$route.path + parseInt((Math.random() * 1000));
    }
  }
}
</script>