import { videoPath } from './api/api';

export default {
	namespaced: true,
	state: {
		videoUrl: null, // 应用场景详情里面的 相关文章
	},
	getters: {
		videoUrl: state => state.videoUrl,
	},
	actions: {
    async getVideoPath({ state, dispatch }) {
      if (state.videoUrl) return;
      const option = {
				showError: false,
				opt: Object.assign({}, videoPath),
			}
			const result = await dispatch('api/fetchAPI', option, {
				root: true
			});
			console.log('视频',result)
      if (result.code===200 && result.data) {
        state.videoUrl = result.data[0].productVideo;
      }
    },
	},
}
