import { bannerList, articleList, subscribeEmail } from './api/api';

export default {
	namespaced: true,
	state: {
		bannerList: [], // 导航栏
		newList: null,
	},
	getters: {
		bannerList: state => state.bannerList,
		newList: state => state.newList,
	},
	actions: {
		async getBannerList({ state, dispatch }) {
			if (!state.bannerList || !state.bannerList.length) {
				const option = {
					showError: false,
					opt: Object.assign({}, bannerList),
				}
				const result = await dispatch('api/fetchAPI', option, {
					root: true
				});
				// if (result.isSuccess && result.data) {
				// 	state.bannerList = result.data;
				// }
				if (result.code===200 && result.data){
					state.bannerList = result.data;
				}
				await console.log('头图列表',result)
			}
			
		},
		async getNewList({ state, dispatch }) {
					if (!state.newList || !state.newList.length) {
						articleList.url = articleList.url+'&parentId=5'+`&pageNum=1&pageSize=9`
						const option = {
							showError: false,
							opt: Object.assign({}, articleList, {
								// data: {
								// 	page: 1,
								// 	size: 9,
								// 	is_top: true,
								// }
							}),
						}
						const result = await dispatch('api/fetchAPI', option, {
							root: true
						});
						console.log('新闻列表',result)
						if (result.code===200 && result.rows) {
							state.newList = result.rows;
							state.newList.push({code:null})
							// state.newList.push({
							// 	title: 'news'
							// });
							console.log('新闻列表',state.newList)
						}
					}
				},
		// 发送订阅邮件
		async beaginSubscribe({ state, dispatch }, params = null) {
			if (!params) return;
			const option = {
				showError: false,
				opt: Object.assign({}, subscribeEmail, {
					data: {
						email: params,
					}
				}),
			}
			const result = await dispatch('api/fetchAPI', option, {
				root: true
			});
			return result.isSuccess
		}
	},
}
