export default {
	zh: {
    title: '公司简介',
    des: '锐思智芯是全球领先的视觉传感器技术革命的领导者。我们创立于2019年，是根植于深圳，并于北京,南京,瑞士设立分公司的全球化科技公司。拥有完整的核心知识产权及自主研发能力，能够填补国内机器视觉领域的技术及专利空白，为中国科技企业走向世界保驾护航。我们以 Hybrid Vision 技术核心，突破性地智创了融合式仿生视觉芯片及一体化机器视觉解决方案，致力于不断突破行业的发展瓶颈，为整个机器视觉生态系统赋能。<br/><br/>我们还拥有一支兼备算法、软件、硬件、芯片研发经验与能力的顶级团队，核心专家皆来自世界顶尖科研公司及机构。在高端视觉传感器领域拥有超过15年的技术积累，合计申请专利近40项，发表国内外文献超200篇；并在仿生视觉领域拥有近7年的研究经验。',
    desMobile: '锐思智芯是全球领先的视觉传感器技术革命的领导者。我们创立于2019年，是根植于深圳，并于北京,南京,瑞士设立分公司的全球化科技公司。拥有完整的核心知识产权及自主研发能力，能够填补国内机器视觉领域的技术及专利空白，为中国科技企业走向世界保驾护航。我们以 Hybrid Vision 技术核心，突破性地智创了融合式仿生视觉芯片及一体化机器视觉解决方案，致力于不断突破行业的发展瓶颈，为整个机器视觉生态系统赋能。<br/><br/>我们还拥有一支兼备算法、软件、硬件、芯片研发经验与能力的顶级团队，核心专家皆来自世界顶尖科研公司及机构。在高端视觉传感器领域拥有超过15年的技术积累，合计申请专利近40项，发表国内外文献超200篇；并在仿生视觉领域拥有近7年的研究经验。',
    propertyTitle: '知识产权',
    propertyDes: 'ALPIX 融合式仿生视觉传感器',
    productDes: '锐思智芯拥有完整独立的核心知识产权体系和行业领先的研发能力，为客户产品畅行全球保驾护航。',
    productDesMobile: '锐思智芯拥有完整独立的核心知识产权体系和行业领先的研发能力，为客户产品畅行全球保驾护航。',
    propertyNum1:"",//14项
    propertyName1:"",//海外国际及地区专利
    propertyNum2:"",//35项
    propertyName2:"",//国家专利
    propertyNum3:"",//8项
    propertyName3:"",//PCT
  },
  en: {
    title: 'About The Company',
    des: 'AlpsenTek GmbH is a leader in revolutionizing vision sensor technology. Our strategy has involved using Hybrid Vision to break new ground with hybrid biomimetic vision chips and an integrated machine vision solution. We are  committed to developing technology breakthroughs for the industry, and empowering the machine vision ecosystem as a whole.<br/><p></p>AlpsenTek holds a complete core set of intellectual property rights<br/>and in-house development capabilities to fill technology gaps<br/>in the field of machine vision. Part of its strategy has involved using<br/>Hybrid Vision to break new ground with hybrid biomimetic vision chips<br/>and an integrated machine vision solution. AlpsenTek is committed to<br/>developing technology breakthroughs for the industry, supporting<br/>Chinese technology companies across the globe, and empowering<br/>the machine vision ecosystem as a whole.<br/><br/>We have an international team of top professionals from elite research firms worldwide with extensive expertise in developing algorithms, software, hardware, and chips. They have over 16 years of research and development experience in developing high-end vision sensors and 8 years of research experience in biomimetic vision.',
    desMobile: 'AlpsenTek is a global leader in revolutionizing vision sensor technology.<br/>It was founded in 2019 and is now a global technology company headquartered in Shenzhen with offices in Beijing,Nanjing and Switzerland. AlpsenTek holds a complete core set of intellectual property rights and in-house development capabilities to fill technology gaps in the field of machine vision. Part of its strategy has involved using Hybrid Vision to break new ground with hybrid biomimetic vision chips and an integrated machine vision solution. AlpsenTek is committed to developing technology breakthroughs for the industry, supporting Chinese technology companies across the globe, and empowering the machine vision ecosystem as a whole.<br/><br/>AlpsenTek employs an international team of top professionals from elite research firms worldwide with extensive expertise in developing algorithms, software, hardware, and chips. They have over 15 years of research and development experience in developing high-end vision sensors, nearly 40 patents, and 200 published papers in China and abroad. They also have 7 years of research experience in biomimetic vision.',
    propertyTitle: 'Intellectual Property Rights',
    propertyDes: 'ALPIX Sensors with hybrid biomimetic vision',
    productDes: 'AlpsenTek has a complete and independent core intellectual property system and industry-leading research and development capabilities,<br/> escorting customers\' products to the world.',
    productDesMobile: 'AlpsenTek has a complete and independent core intellectual property system and industry-leading research and development capabilities, escorting customers\' products to the world.',
    propertyNum1:"", //14
    propertyName1:"", //Patents in overseas countries and regions
    propertyNum2:"", //35
    propertyName2:"", //Chinese<br/>Patents
    propertyNum3:"", //8
    propertyName3:"", //PCT
  }
}