export default {
	zh: {
    tag: "相关文章",
    type1: "手机",
    type2: "大屏式智能设备",
    type3: "智能家居设备",
    type4: "机器人",
    type5: "汽车",
    type6: "安防监控",
    more:"了解更多",
    scenetype1:"手机",
    scenedes1:"应用于智能手机前、后置摄像头，助力合作伙伴成为手机拍摄领域的 “Game-changer”。<br/><br/>ALPIX 所输出的事件流数据，从源头消除数据冗余，解决传统视觉算法数据量过大，系统延时高，资源消耗大的问题，真正做到高效实时算法，帮助实现更优的自动对焦、人像提取、特定物体优化等视觉功能。<br/><br/>ALPIX 将事件流数据和传统图像数据像素级融合，有效实现手机拍摄的图像质量增强、暗光拍摄增强、超高速视频拍摄、高动态范围拍摄等进阶功能。",
    scenetype2:"大屏式智能设备",
    scenedes2:"应用于手提电脑、平板电脑、电视等大屏设备的可视及交互系统，致力于与行业伙伴们一起打造全新的交互体验。<br/><br/>ALPIX 依托其事件流数据的低数据冗余性和捕捉动态变化的高效性，降低系统处理成本，提高判断精准度，帮助实现更具市场竞争力的人机交互（包括人脸特征和身体动作的监测、识别及追踪）、AR/VR、智能唤醒等功能。",
    scenetype3:"智能家居设备",
    scenedes3:"应用于智能家居设备的可视及探测系统，致力于与行业伙伴们一起构建更智能的家居生态。<br/><br/> ALPIX 依托其事件流数据的低数据冗余性、捕捉动态变化的高效性和隐私保护性，降低系统处理成本，规避漏判及误判现象，实现低功耗的智能触发、智能追踪和隐私环境下的监控功能。<br/><br/>基于 ALPIX 事件流数据的高动态范围优势，实现全天候，包括无光照条件下的设备正常运行。",
    scenetype4:"机器人",
    scenedes4:"应用于包括家用机器人、无人机、物流机器人等各种机器人设备的可视及感应系统，助力合作伙伴打造更具竞争力的智能机器人。<br/><br/>ALPIX 融合方案，既能基于事件流数据实现低功耗的3D半稠密点云图构建，又能基于传统图像数据成熟的算法实现 3D稠密点云图的构建，并使得两者之间无需复杂匹配算法快速融合，带来更具市场竞争力的SLAM功能。<br/><br/>基于 ALPIX 事件流数据的低数据冗余性，降低系统处理成本，实现快速响应突发事件的功能。<br/><br/>基于 ALPIX 事件流数据的高动态范围优势，实现各种光照条件下的正常运行。<br/><br/>",
    scenetype5:"汽车",
    scenedes5:"应用于自动驾驶领域的主要视觉传感器，致力于成为自动驾驶技术发展的助推器。<br/><br/>ALPIX 融合方案，能够与TOF等主流传感器完美配合，构建完善的自动驾驶视觉系统，实现更精细的图像判断，更精确的测距，以及更高速实时的预判功能。<br/><br/>基于 ALPIX 事件流数据的低数据冗余性，降低系统处理成本，实现自动驾驶低延时研判功能，从而快速应对道路突发状况。<br/><br/>基于 ALPIX 事件流数据的高动态范围优势，实现自动驾驶高环境适应能力，避免极端光照条件或明暗极速变化情况下出现的视觉盲区现象。",
    scenetype6:"安防监控",
    scenedes6:"应用于专业安防监控相机，赋能安防，助力构建智慧城市。<br/><br/>ALPIX 依托其事件流数据的高动态范围优势，实现暗光条件下的清晰拍摄功能。<br/><br/>基于 ALPIX 事件流数据捕捉动态变化的高效性，实现端测对特定特征的智能判断和跟踪功能，以及快速对特征信息的标识和检索功能。",
  },
  en: {
    tag: "Related News",
    type1: "Smartphones",
    type2: "Big-screen Smart Devices",
    type3: "Smart Homes",
    type4: "Robotics",
    type5: "Automobiles",
    type6: "Surveillance",
    more: "Learn more",
    scenetype1:"Smartphones",
    scenedes1:"The front and rear cameras of smartphones offer companies a golden opportunity to redefine and lead in mobile imaging.<br/><br/>The event streaming of ALPIX eliminates data redundancy at the source and resolves the issues of traditional vision algorithms, such as excessive data volume, system latency, and resource consumption. It provides an efficient and real-time algorithm to offer innovative features for vision technology, including auto-focus, portrait extraction, and specific object optimization.<br/><br/>ALPIX combines the data of event streaming and traditional images at the pixel level to effectively achieve advanced functions for mobile imaging. These functions include image quality enhancement, low-light performance enhancement, ultrafast video recording, and high dynamic range.",
    scenetype2:"Big-screen Smart Devices",
    scenedes2:"ALPIX redefines user experience with compatibility for the visual and interactive systems of big-screen devices, such as laptops, tablets, and TVs.<br/><br/>ALPIX employs low redundancy event streaming and high efficiency in capturing dynamic changes to lower system processing costs, boost recognition accuracy, and achieve highly competitive features. These features include machine analysis of facial and body behavior, AR/VR, and smart wake-up calls.",
    scenetype3:"Smart Homes",
    scenedes3:"ALPIX offers a smarter home ecosystem with enhancements to the vision and detecting systems of smart home devices.<br/><br/>ALPIX employs low redundancy event streaming, high efficiency in capturing dynamic changes, and superior security in protecting privacy. ALPIX can consequently lower system processing costs, avoid false negatives or false positives, realize low-power intelligent activation, and implement intelligent tracking and surveillance in a private environment.<br/><br/>The high dynamic range of ALPIX in event streaming enables it to work around the clock and provide continuous equipment operation in environments with minimum lighting.",
    scenetype4:"Robotics",
    scenedes4:"ALPIX enhances vision and sensing systems for the highly competitive machines of the future, including domestic robots, drones, and logistics robots.<br/><br/>The ALPIX hybrid solution can build semi-dense point clouds based on both event streaming analysis with low power consumption and the mature algorithms of traditional image data. It can quickly combine the two without using a complex matching algorithm to take full advantage of the more competitive functions of Simultaneous Localization and Mapping (SLAM).<br/><br/>ALPIX utilizes low redundancy event streaming to lower system processing costs and accelerate emergency responses.<br/><br/>Thanks to the wide dynamic range of its event streaming, ALPIX can operate normally under a wide variety of lighting conditions.<br/><br/>",
    scenetype5:"Automobiles",
    scenedes5:"ALPIX can facilitate the development of autonomous driving vehicles with applications for the main vision sensors used in autonomous driving technology.<br/><br/>The hybrid solution of ALPIX provides perfect compatibility with mainstream sensors, including sensors with Time-of-Flight (ToF) technology, to build a perfect vision system for autonomous driving vehicles. The improved system achieves greater precision for image recognition, accuracy for ranging, and speed for real-time prediction.<br/><br/>ALPIX utilizes low data redundancy in its event streaming to lower system processing costs and provide autonomous driving systems with low latency for quick responses to traffic emergencies.<br/><br/>ALPIX offers a wide dynamic range for event streaming to increase environmental adaptability and avoid blind spots from extreme or rapidly changing conditions in lighting.",
    scenetype6:"Surveillance",
    scenedes6:"ALPIX can be used for professional surveillance cameras to enhance security and help build smart cities.<br/><br/>The wide dynamic range of ALPIX event streaming provides clear images despite poor lighting conditions.<br/><br/>ALPIX utilizes event streaming to facilitate the capturing of dynamic environment changes for intelligent recognition, tracking of specific features and end-side objects, and identification and retrieval of feature information.",
  }
}