const moment = require('moment');

const formatTime = (params = null, format = 'YYYY-MM-DD') => {
  if (!params) return;
  const text = moment(params).format(format);
  return text;
}


const toUpperCase = (params) => {
  if (!params) return;
  return params.toUpperCase();
}


export  {
  formatTime,
  toUpperCase
}