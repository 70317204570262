export default async function(options) {
	// 请求的业务流程
	const axios = this.getAxios();

	if (!options || !options.opt) {
		console.warn(' u should have options.opt');
		return;
	}
	
	// 默认情况显示菊花加载
	const showLoading = (typeof options.showLoading === 'boolean') ? options.showLoading : true;
	const defaultOpt = this.getOptions();
	const beforeSend = options.beforeSend || null;
	const complete = options.complete || null;
	let opt = options.opt;

	// 如果是单个请求合并成多个请求
	const isArray = Array.isArray(opt);
	opt = isArray ? opt : [opt];
	const temp = opt.map((item) => {
		const data = Object.assign(defaultOpt, item);
		return axios.request(data).then(res => res.data).catch(e => e);
	});
	
	// 开始请求
	if (beforeSend || typeof beforeSend === 'function') {
		const params = beforeSend();
		if (params === false) return;
	}
	
	// loading start
	if (showLoading && this.showLoading) {
		this.showLoading();
	}
	
	const result = await Promise.all(temp).catch(e => e);
	
	// loading end 
	if (showLoading && this.hideLoading) {
		this.hideLoading();
	}
	
	// 请求结束
	if (complete || typeof complete === 'function') {
		complete();
	}

	return isArray ? result : result[0];
}
