export default {
	zh: {
    productTitle:"了解公司新闻、活动信息，以及产品与技术动态",
    productTitle1:"公司新闻",
    productTitle2:"活动信息",
    productTitle3:"产品与技术动态",
    readMore:"阅读更多",
    companyNews:"公司新闻",
    productNews:"产品新闻",
    technologyTrends:"技术动态",

  },
  en: {
    productTitle:"Company news - Events information - Product and technology updates",
    productTitle1:"Company News",
    productTitle2:"Events Information",
    productTitle3:"Product & Technology News",
    readMore:"Read More",
    companyNews:"Company News",
    productNews:"Product News",
    technologyTrends:"Technology Trends",
  }
}