import {
	departmentList,
	cityList,
	jobList,
	jobInfo
} from './api/api';


export default {
	namespaced: true,
	state: {
		departmentList: [], // 部门列表
		addressList: [], // 地址列表
		jobList: [], // 岗位列表
		page: {
			page: 0,
			size: 6
		},
		haveMore: true,
		jobInfo: {}, // 加入我们详情页面
	},
	getters: {
		departmentList: state => state.departmentList,
		addressList: state => state.addressList,
		jobList: state => state.jobList,
		page: state => state.page,
		haveMore: state => state.haveMore,
		jobInfo: state => state.jobInfo,
	},
	actions: {
		async getSelectList({
			state,
			dispatch
		}) {
			const option = {
				showError: false,
				opt: [
					Object.assign({}, departmentList),
					Object.assign({}, cityList)
				],
			}
			const result = await dispatch('api/fetchAPI', option, {
				root: true
			});
			console.log('城市列表', result)
			if (result[0].code===200) {
				state.departmentList = [{
					id: null,
				}].concat(result[0].data);
				console.log('state.departmentList',state.departmentList)
			}
			if (result[1].code===200) {
				state.addressList = [{
					id: null,
				}].concat(result[1].data);
				console.log('state.addressList',state.addressList)
			}
		},
		async getJobList({
			state,
			dispatch
		}, params = {
			city_id: '',
			department_id: '',
			pageNum: 1,
			pageSize: 6
		} //岗位列表
		) {
			console.log('请求参数',jobList)
			var tempParams = {
				url: jobList.url+`&department=${encodeURIComponent(params.department_id  || '') || ''}&city=${encodeURIComponent(params.city_id || '') || ''}&pageNum=${params.pageNum}&pageSize=${params.pageSize}`,
				method: jobList.method
			}
			const option = {
				showError: false,
				opt: Object.assign({}, tempParams, {
				}),
			}
			const result = await dispatch('api/fetchAPI', option, {
				root: true
			});
			console.log('岗位',result)
			if (result.code===200 && result.rows) {
				state.jobList=[];
				state.jobList.push(...result.rows)
				
				state.haveMore = state.jobList.length < result.total
			}
		},
		async getJobDetail({
			state,
			dispatch
		}, params = null) { //岗位详情
			if (!params) return;
			var tempGet = {
				url:`${jobInfo.url}&code=${params}`,
				method: jobInfo.method, 
			}
			console.log('tempUrl',tempGet)
			const option = {
				showError: false,
				opt: Object.assign({}, tempGet, {
					// data: {
					// 	id: params
					// }
				}),
			}
			const result = await dispatch('api/fetchAPI', option, {
				root: true
			});
			console.log('招聘详情',result)
			if (result.code===200 && result.rows) {
				state.jobInfo = result.rows[0];
			}
		},

	},
}
