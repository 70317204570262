<template>
	<div class="head_content">
		<component v-if="isMobileBrowse" :menuList="list" :is="'r-header-mobile'"/>
		<component v-else :menuList="list" :is="'r-header-pc'"/>
	</div>
</template>

<script>
	import common from '@/mixin/common.js';
	export default {
		mixins: [ common ],
		name: 'r-header',
		data() {
			return {
				// list: ['home', 'technology', 'scene', 'product', 'news', 'about', 'join', 'contact'],
				list: ['home', 'technology', 'scene', 'product', 'news', 'about', 'join'],
			}
		},
	}
</script>

<style scoped lang="scss">
	.head_content {
		width: 100%;
	}
</style>
