import Cookies from 'js-cookie'

export function getCookie(key = null) {
	if (!key) return null;
	return Cookies.get(key)
}

export function setCookie(key = null, value = null) {
	if (!key || !value) return null;
	return Cookies.set(key, value);
}
