import Vue from 'vue';
import {
	Row,
	Col,
	Carousel,
	Input,
	Select,
	message,  // 轻消息弹窗
	Dropdown,
	Anchor,
	Icon,
	BackTop,
	Modal,
	// Breadcrumb,
	// Layout,
} from 'ant-design-vue';


Vue.component(Row.name, Row)
Vue.component(Col.name, Col);
Vue.component(Carousel.name, Carousel);
Vue.component(Input.name, Input);
Vue.component(Input.Search.name, Input.Search);
Vue.component(Select.name, Select);
Vue.component(Select.Option.name, Select.Option);
Vue.component(Dropdown.name, Dropdown);
Vue.component(Anchor.name, Anchor);
Vue.component(Anchor.Link.name, Anchor.Link);
Vue.component(Icon.name, Icon);
Vue.component(BackTop.name, BackTop);
// Vue.component(Modal.name, Modal);
Vue.use(Modal);

// Vue.component(Breadcrumb.name, Breadcrumb);
// Vue.component(Breadcrumb.Item.name, Breadcrumb.Item);
// Vue.component(Layout.name, Layout);
// Vue.component(Layout.Header.name, Layout.Header);
// Vue.component(Layout.Content.name, Layout.Content);
// Vue.component(Layout.Footer.name, Layout.Footer);


Vue.prototype.$message = message;